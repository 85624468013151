import { Alert, Button, Card, Col, Row, Spinner, Stack } from 'react-bootstrap';
import { Order, OrderPricing, LocationOrders } from '../../../types/orders.type';
import { useEffect, useRef, useState } from 'react';
import { useGetAllDiscountsQuery } from '../../../services/endpoints/orders/discounts';
import OrderCheckout from './OrderCheckout';
import { useGetAllOrdersQuery, useUpdateOrderTransactionMutation } from '../../../services/endpoints/orders/orders';
import { GetQueryParamsWithFilterArray } from '../../../types/api.type';
import { getCurrentUser } from '../../../services/helper';
import { useSearchParams } from 'react-router-dom';
import { useGetStripeIntentMutation } from '../../../services/endpoints/stripe/stripe';
import { PaymentMethod } from '@stripe/stripe-js';

const currentUser = getCurrentUser();

interface CheckoutProps {
  // editing?: boolean;
}

const Checkout: React.FC<CheckoutProps> = ({}) => {
  const [ locationOrders, setLocationOrders ] = useState<LocationOrders[]>([]);
  const [ locationOrdersLoading, setLocationOrdersLoading ] = useState<boolean>(true);
  const [ updateOrder ] = useUpdateOrderTransactionMutation();
  const [ getPaymentIntent ] = useGetStripeIntentMutation();

  let [searchParams, setSearchParams] = useSearchParams();
  const orderIds_qs = searchParams.get('order_ids');
  const locationId_qs = searchParams.get('location_id');
  const courseIds_qs = searchParams.get('course_ids');
  const paymentIntent_qs = searchParams.get('payment_intent');
  const clientSecret_qs = searchParams.get('payment_intent_client_secret');
  const complete_qs = searchParams.get('complete');

  const finalizing = !complete_qs && !!(paymentIntent_qs && clientSecret_qs && locationId_qs);

  const filters: GetQueryParamsWithFilterArray = {data: {page: 1, entries: 10,  ...(currentUser ? {filterBy: [{field: 'parent_id', operator: 'equals', value: currentUser?.id}]} : {})}, scope: "incomplete"};
  let completeFilters;
  if (orderIds_qs) {
    completeFilters = JSON.parse(JSON.stringify(filters));
    completeFilters.data = {...filters.data, filterBy: [{field: 'id', operator: 'includes', value: orderIds_qs?.split(',')}]};
    completeFilters.scope = undefined;
  }
  const { data: completedOrders, isFetching: fetchingCompletedOrders } = useGetAllOrdersQuery(completeFilters, {skip: !orderIds_qs});
  const { data, error, isLoading, isFetching } = useGetAllOrdersQuery(filters, {skip: finalizing});
  const { data: discounts, error: discountError, isLoading: discountsLoading } = useGetAllDiscountsQuery({scope: 'incomplete', ...(currentUser ? {data: {filterBy: [{field: 'user_id', operator: 'equals', value: currentUser?.id}]}} : {})});

  const renderAfterCalled = useRef(false);

  const updateOrders = async () => {
    if (!renderAfterCalled.current) {
      renderAfterCalled.current = true;
      let orderIds = orderIds_qs?.split(',').map(id => parseInt(id));
      if (paymentIntent_qs && clientSecret_qs && locationId_qs) {
        const pi = await getPaymentIntent({payment_intent: paymentIntent_qs, location_id: +locationId_qs});
        if (orderIds && orderIds.length > 0 && orderIds.every(i => Number.isSafeInteger(i))) {
          let payload: any = {ids: orderIds, transaction_id: paymentIntent_qs};
          if ('error' in pi) {
            console.log(pi['error']);
          }
          else {
            if ((pi?.data?.payment_intent?.payment_method as PaymentMethod)?.card?.last4) {
              payload.last_4 = (pi?.data?.payment_intent?.payment_method as PaymentMethod)?.card?.last4;
            }
          }
          await updateOrder(payload);
          setSearchParams({complete: locationId_qs, course_ids: courseIds_qs ?? ''});
        }
        else {
          console.error("Invalid order ids:", orderIds);
        }
      }
    }
  }

  useEffect(() => {
    updateOrders();
  }, []);

  useEffect(() => {
    if (!isFetching && data?.results) {
      let values = data.results as Order[];
      if (!fetchingCompletedOrders && completedOrders?.results) {
        values = (completedOrders.results as Order[]).concat(values);
      }
      let obl = values?.reduce((acc, curr) => {
        let location = curr.course?.venue?.location;
        if (curr.course?.venue?.location === undefined) {
          return acc;
        }
        if ((curr.registrations?.length ?? 0) > 0) {
          const pricing: OrderPricing = {
            fullPrice: curr.registrations.reduce((acc, r) => acc + r.full_price, 0),
            price: curr.registrations.reduce((acc, r) => acc + r.price, 0),
          };
          let opc = {...curr, cost: pricing};
          let idx = acc.findIndex(o => o.location.id === location?.id);
          console.log(idx, location?.id);
          if (idx !== -1 && acc[idx] && acc[idx].orders && acc[idx].orders.length > 0) {
            acc[idx].orders.push(opc);
          }
          else {
            acc.push({location, orders: [opc]});
          }
        }
        return acc;
      }, [] as LocationOrders[]);

      setLocationOrders(obl);
      setLocationOrdersLoading(false);
    }
  }, [data, isFetching, completedOrders, fetchingCompletedOrders]);

  if (isLoading || locationOrdersLoading || isFetching) {
    return (
      <Stack style={{alignItems: "center"}} className="m-5">
        <Spinner animation="border" variant="primary" />
      </Stack>
    )
  }

  console.log("LOs:", locationOrders.map(lo => lo.location.id));

  return (
    <Card body>
      {locationOrders.length > 1 && (
        <Alert variant='info' className="text-info">Orders must be paid separately for each franchise location they are associated with</Alert>
      )}
      {(!locationOrders || locationOrders.length === 0) && (
        <Stack className="text-center">
          <br />
          <h2>Uh Oh! Looks like your cart is empty! Click below to start shopping</h2>
          <br />
          <Row>
            <Col sm={{span: 4, offset: 4}}>
              <Button href="/sessions" variant={'primary'}>FIND A SESSION</Button>
            </Col>
          </Row>
        </Stack>
      )}
      {locationOrders.map((obl) => {
        return <OrderCheckout key={obl.location.id} locationOrders={obl} discounts={discounts?.results ?? []} />
      })}

    </Card>
  )
}

export default Checkout;