import "./RecipeStats.css";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import { Col, Row } from "react-bootstrap";
import { getCurrentUser } from "../../../services/helper";

interface RecipeStats {
  recipe?: Recipe;
  lessonPlan?: LessonPlan | null;
}

const RecipeStats: React.FC<RecipeStats> = ({ recipe, lessonPlan }) => {
  const currentUser = getCurrentUser();
  let prepTime = 0;
  let cookTime = 0;
  let minServed = 0;
  let maxServed = 0;
  if (currentUser?.hasDashAccess) {
    if (!isNaN(lessonPlan?.lesson_prep_time!)) {
      prepTime = lessonPlan!.lesson_prep_time;
    }
    if (!isNaN(lessonPlan?.lesson_cook_time!)) {
      cookTime = lessonPlan!.lesson_cook_time;
    }
    if (!isNaN(lessonPlan?.lesson_min_served!)) {
      minServed = lessonPlan!.lesson_min_served;
    }
    if (!isNaN(lessonPlan?.lesson_max_served!)) {
      maxServed = lessonPlan!.lesson_max_served;
    }
  } else {
    prepTime = recipe ? recipe.prep_time : (lessonPlan ? lessonPlan.prep_time : 0);
    cookTime = recipe ? recipe.cook_time : (lessonPlan ? lessonPlan.cook_time : 0);
    minServed = recipe ? recipe.min_served : (lessonPlan ? lessonPlan.min_served : 0);
    maxServed = recipe ? recipe.max_served : (lessonPlan ? lessonPlan.max_served : 0);
  }
  return (
    <>
      <div>
        <div className="recipe-features">
          <Row className="columns-recipe-features">
            <Col lg={4} className="column-recipe-features">
              <div className="div-recipe-features-copy">
                <div className="subheader fw-bold mb-0">prep time</div>
                <div>{prepTime} minutes</div>
              </div>
            </Col>
            <Col lg={4} className="column-recipe-features">
              <div className="div-recipe-features-copy">
                <div className="subheader fw-bold mb-0">cook time</div>
                <div>{cookTime} minutes</div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="div-recipe-features-copy">
                <div className="subheader fw-bold mb-0">makes</div>
                <div>
                  {minServed} - {maxServed} servings
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeStats;
