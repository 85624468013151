import { Outlet, useLocation, useParams } from "react-router-dom";
import { SFCNavbar } from "../navbar/SFCNavbar";
import { MimicHeader } from "../navbar/MimicHeader";
import "./SFCLayout.css";
import { FooterSection } from "../footer/FooterSection";

export const SFCLayout = () => {
  const location = useLocation();
  return (
    <>
      <MimicHeader />
      <SFCNavbar />
      <div className="content min-vh-100" style={{ background: location.pathname !== '/recipes' ? "#FAFAFA" : "#FFF" }}>
        <Outlet />
        <FooterSection />
      </div>
    </>
  );
};