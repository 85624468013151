import { Page } from '../../types/website.types';
import ImageWithErrorHandling from '../../components/image/ImageWithErrorHandling';
import { getPartition, stripHtmlTags } from '../../utils/utils';
import { DateCell } from '../../components/tables/cellComponents/DateCell';
import { Col } from 'react-bootstrap';

export interface BlogCardProps {
  page: Page;
}

export const BlogCard: React.FC<BlogCardProps> = ({ page }) => {

  return (
    <a href={`/pages/${page.slug}`} className="link-block-card w-inline-block">
      <div className="div-blog-card">
        <div className="div-blog-card-image-wrapper">
          <ImageWithErrorHandling className="w-100 h-auto px-4" src={`${page?.s3_base_url}/${getPartition(page?.id)}/medium/${page?.image_file_name}`} />
        </div>
        <div className="div-blog-card-details">
          <div>
            <div className="subheader blog-card"><DateCell date={page.published_on}/></div>
            <div className="blog-card-title">{page.name}</div>
          </div>
          <div>
            <div className="text-block-product">{`by ${page.author}`}</div>
            <div className="text-available">
              {stripHtmlTags(page.content).slice(0, 72)}...
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
