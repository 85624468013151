import "./RecipePagination.css";
interface RecipePaginationProps {
  page: number;
  count: number;
  onPageClick: (page: number) => void;
}

const RecipePagination: React.FC<RecipePaginationProps> = ({ page, count, onPageClick }) => {
  let totalPages = 0;
  if (count > 0) {
    totalPages = Math.ceil(count / 9);
  }
  return (
    <div className="recipe-pagination">
      {Array.from({ length: totalPages }, (_, index) => (
        <a
          key={`page-${index}`}
          onClick={() => onPageClick(index + 1)}
          className={`link-pagination ${index + 1 === page ? "fw-bold" : ""}`}
        >
          <div>{ index + 1}</div>
        </a>
      ))}
    </div>
  );
};

export default RecipePagination;
