import { GetQueryParamsWithFilterArray, GetQueryResponse } from '../../../types/api.type';
import { Snippet } from '../../../types/website.types';
import { sfcApi } from '../../api';

export const snippetApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSnippets: builder.query<GetQueryResponse, GetQueryParamsWithFilterArray | void>({
      query: (params) => ({
        url: `snippets${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Snippet']
    }),
    getSnippet: builder.query<{ snippet: Snippet }, number>({
      query: (id) => `snippets/${id}`,
      providesTags: (result, error, id) => [{ type: 'Snippet', id }]
    }),
    createSnippet: builder.mutation<{ snippet: Snippet }, Snippet>({
      query: (data) => ({
        url: `snippets/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Snippet']
    }),
    updateSnippet: builder.mutation<{ snippet: Snippet }, Snippet>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `snippets/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) => [{ type: 'Snippet', id: result?.snippet?.id }, 'Snippet']
    }),
    deleteSnippet: builder.mutation<{ snippet: Snippet }, number>({
      query: (id) => ({
        url: `snippets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Snippet'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Snippet', id })), 'Snippet']
    }),
  }),
});

export const {
  useGetAllSnippetsQuery,
  useGetSnippetQuery,
  useCreateSnippetMutation,
  useUpdateSnippetMutation,
  useDeleteSnippetMutation,
} = snippetApi;