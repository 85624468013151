import { useCallback, useState } from "react";
import "./RecipesFiltersItem.css";
import { FaChevronLeft, FaChevronDown } from "react-icons/fa6";
import { getTrackBackground, Range } from "react-range";
import { Form } from "react-bootstrap";
import { debounce, range } from "lodash";

const STEP = 1;
const MIN = 0;
const MAX = 100;

interface RecipeFiltersItem {
  title: string;
  type?: string;
  field: string;
  handleSubmit: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  checkboxValues?: { name: string; value: string }[];
};

const RecipeFiltersItem: React.FC<RecipeFiltersItem> = ({
  title,
  type = "input",
  field,
  checkboxValues,
  handleSubmit,
  setFieldValue,
}) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [rangeValues, setRangeValues] = useState([MIN, MAX]);
  const [toggleContent, setToggleContent] = useState(false);

  const handleToggle = () => {
    const toggleValue = !toggleContent;
    setToggleContent(toggleValue);
  };

  let className = "nav-item";
  if (toggleContent) {
    className += " active";
  }

  const debouncedSubmit = useCallback(
    debounce(() => {
      handleSubmit();
    }, 500),
    [handleSubmit]
  );

  const handleSlideChange = (values: number[]) => {
    setRangeValues(values);
    updateValues(values);
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    let checkValues = [...checkedValues];
    if (checked) {
      checkValues.push(id);
    } else {
      checkValues = checkValues.filter((value) => value !== id);
    }
    setCheckedValues(checkValues);
    updateValues(checkValues);
  };

  const updateValues = (value: any) => {
    setFieldValue!(field, value);
    debouncedSubmit();
  }

  return (
    <li className={className}>
      <a
        className="nav-link align-middle px-0 text-info text-uppercase fw-bold"
        onClick={handleToggle}
      >
        <span className="ms-1 d-inline">{title}</span>
        <span className="arrow">
          {toggleContent ? <FaChevronDown /> : <FaChevronLeft />}
        </span>
      </a>
      {toggleContent && (
        <div className="submenu">
          <div className="submenu-content">
            {type === "input" && (
              <Form.Control
                type="text"
                onChange={(e: any) => {
                  updateValues(e.target.value);
                }}
              />
            )}
            {type === "slide" && (
              <Range
                draggableTrack
                values={rangeValues}
                step={STEP}
                min={MIN}
                max={MAX}
                rtl={false}
                onChange={handleSlideChange}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "36px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "14px",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values: rangeValues,
                          colors: ["#ccc", "#bcc74f", "#ccc"],
                          min: MIN,
                          max: MAX,
                          rtl: false,
                        }),
                        alignSelf: "center",
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, index, isDragged }) => (
                  <div
                    {...props}
                    key={props.key}
                    style={{
                      ...props.style,
                      height: "28px",
                      width: "28px",
                      borderRadius: "50%",
                      backgroundColor: "#FFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 2px 6px #AAA",
                    }}
                  >
                    {isDragged && (
                      <div className="slider-thumb">
                        <span id="value">{rangeValues[index]}</span>
                      </div>
                    )}
                  </div>
                )}
              />
            )}
            {type == "checkbox" && (
              <div className="mb-3">
                {checkboxValues?.map((checkbox, index) => {
                  return (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      id={checkbox.value}
                      label={checkbox.name}
                      onChange={handleCheckboxChange}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  );
};

export default RecipeFiltersItem;