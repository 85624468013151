import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { InstructorCourseRequest } from '../../../types/course.type';
import { sfcApi } from '../../api';

export const instructorCourseRequest = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInstructorCourseRequests: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: 'instructor-course-requests',
        method: 'POST',
        body: params?.data,
      }),
    }),
    getInstructorCourseRequests: builder.query<{ instructorCourseRequest: InstructorCourseRequest }, number>({
      query: (id) => `instructor-course-requests/${id}`,
    }),
  }),
});

export const {
  useGetAllInstructorCourseRequestsQuery,
  useGetInstructorCourseRequestsQuery,
} = instructorCourseRequest;
