import { ReactNode } from "react";
import { Image } from "react-bootstrap";
import {
  BsCalendar3,
  BsCart3,
  BsCashCoin,
  BsFileEarmarkRichtext,
  BsFillGeoAltFill,
  BsGlobe,
  BsMortarboardFill,
  BsPeopleFill,
  BsStopwatch,
} from "react-icons/bs";
import { LuFileClock } from "react-icons/lu";
import PinWiskLogo from "../../assets/images/pin_wisk.png";
import { getCurrentUser } from "../../services/helper";

const currentUser = getCurrentUser();

export interface NavItem {
  icon?: ReactNode;
  title: string;
  url?: string;
  urls?: string[];
  subItems?: NavItem[];
  hidden?: boolean;
}

export const NavItems = (isAdmin: boolean) => [
  {
    icon: <Image src={PinWiskLogo} width={24} height={24} />,
    title: "SFC Home",
    url: "/",
  },
  {
    icon: <BsCalendar3 size={24} />,
    title: "Calendar",
    url: "/admin/dashboard",
  },
  {
    icon: <BsStopwatch size={24} />,
    title: "Schedule",
    subItems: isAdmin
      ? [
          {
            title: "Sessions & Classes",
            url: "/admin/schedule/sessions",
            urls: ["/admin/schedule/classes", "/admin/schedule/waitlists"],
          },
          {
            title: "Schedule Needs",
            url: "/admin/schedule-needs/need-instructor",
            urls: [
              "/admin/schedule-needs/need-substitute",
              "/admin/schedule-needs/instructor-session-requests",
            ],
          },
        ]
      : [
          {
            title: "My Sessions",
            url: "/admin/schedule/sessions",
          },
          {
            title: "Available Sessions",
            url: "/admin/schedule-needs/need-substitute",
          },
        ],
  },
  {
    icon: <BsPeopleFill size={24} />,
    title: "People",
    subItems: [
      {
        title: "Dash Users",
        url: "/admin/dash-users",
      },
      {
        title: "SFC Users",
        url: "/admin/sfc-users",
      },
    ],
  },
  ...(isAdmin
    ? [
        {
          icon: <BsFileEarmarkRichtext size={24} />,
          title: "Content",
          subItems: [
            {
              title: "Lesson Plans",
              url: "/admin/content/lesson-plans",
            },
            {
              title: "Recipes",
              url: "/admin/content/recipes",
            },
            {
              title: "Documents",
              url: "/admin/content/documents",
            },
            {
              title: "Themes",
              url: "/admin/content/themes",
            },
            
            {
              title: "Videos",
              url: "/admin/content/videos",
            },
            {
              title: "Joke Categories",
              url: "/admin/content/joke-categories",
            },
            {
              title: "Jokes",
              url: "/admin/content/jokes",
            },
            {
              title: "Featured Ingredients",
              url: "/admin/content/featured-ingredients",
            },
            {
              title: "Country Facts",
              url: "/admin/content/country-facts",
            },
            {
              title: "Food Histories",
              url: "/admin/content/food-histories",
            },
            {
              title: "Kitchen Skills",
              url: "/admin/content/kitchen-skills",
            },
            {
              title: "Questions",
              url: "/admin/content/questions",
            },
          ],
        },
      ]
    : []),
  ...(currentUser?.canSeeFinanceMenu && isAdmin
    ? [
        {
          icon: <BsCashCoin size={24} />,
          title: "Finance",
          subItems: [
            {
              title: "Invoices",
              url: "/admin/finance/invoices",
            },
            {
              title: "Expenses",
              url: "/admin/finance/expenses",
            },
            {
              title: "Receipts",
              url: "/admin/finance/receipts",
            },
            {
              title: "Payments",
              url: "/admin/finance/payments",
            },
          ],
        },
      ]
    : []),

    ...(currentUser?.canSeeFinanceMenu && currentUser.isInstructor
      ? [
          {
            icon: <BsCashCoin size={24} />,
            title: "Finance",
            subItems: [
              {
                title: "Current Entries",
                url: "/admin/finance/current-entries",
              },
              {
                title: "Past Timesheets",
                url: "/admin/finance/expenses",
              },
             
            ],
          },
        ]
      : []),
  ...(currentUser?.canSeeFinanceMenu
    ? [
        {
          icon: <LuFileClock size={24} />,
          title: "Timesheets",
          subItems: [
            {
              title: "Master Timesheet",
              url: "/admin/timesheets/master-timesheet",
              hidden: !currentUser?.isSuper,
            },
            {
              title: `Current Timesheet${currentUser?.isSuper ? "s" : ""}`,
              url: "/admin/timesheets/current-timesheet",
            },
            {
              title: "Past Timesheets",
              url: "/admin/timesheets/past-timesheets",
            },
            {
              title: "Overrides",
              url: "/admin/timesheets/timesheet-overrides",
              hidden: !currentUser?.isLocal,
            },
          ],
        },
      ]
    : []),
  ...(isAdmin
    ? [
        {
          icon: <BsCart3 size={24} />,
          title: "Orders",
          subItems: [
            {
              title: "Orders",
              url: "/admin/orders/orders",
            },
            {
              title: "Refunds",
              url: "/admin/orders/refunds",
            },
            {
              title: "Scholarships",
              url: "/admin/orders/scholarships",
            },
            {
              title: "Discounts",
              url: "/admin/orders/discounts",
            },
            {
              title: "Promotional Codes",
              url: "/admin/orders/promotional-codes",
            },
          ],
        },
      ]
    : []),
  ...(isAdmin
    ? [
        {
          icon: <BsFillGeoAltFill size={24} />,
          title: "Places",
          subItems: [
            {
              title: "Venues",
              url: "/admin/places/venues",
            },
            {
              title: "Locations",
              url: "/admin/places/locations",
            },
            {
              title: "Territories",
              url: "/admin/places/territories",
            },
            {
              title: "Organizations",
              url: "/admin/places/organizations",
            },
            {
              title: "Zip Codes",
              url: "/admin/places/zip-codes",
            },
          ],
        },
      ]
    : []),
  {
    icon: <BsMortarboardFill size={24} />,
    title: "Training",
    subItems: [
      {
        title: "Guides",
        url: "/admin/training/guides",
      },
      {
        title: "Steps",
        url: "/admin/training/steps",
      },
      {
        title: "Videos",
        url: "/admin/training/videos",
      },
    ],
  },
  ...(isAdmin
    ? [
        {
          icon: <BsGlobe size={24} />,
          title: "Website",
          subItems: [
            {
              title: "News Items",
              url: "/admin/website/news-items",
            },
            {
              title: "Pages",
              url: "/admin/website/pages",
            },
            {
              title: "Snippets",
              url: "/admin/website/snippets",
            },
            {
              title: "Team Profiles",
              url: "/admin/website/team-profiles",
            },
            {
              title: "Feedbacks",
              url: "/admin/website/feedbacks",
            },
            // {
            //   title: 'Franchisee Leads',
            //   url: '/admin/website/franchisee-leads'
            // },
            {
              title: "Galleries",
              url: "/admin/website/galleries",
            },
          ],
        },
      ]
    : []),
];

export const SFCNavItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: `Why we're grape`,
    url: "/why-we-are-grape",
  },
  {
    title: 'Recipes',
    url: '/recipes',
  },
  {
    title: "Find a cooking session",
    url: "/sessions",
    subItems: [
      {
        title: "School Programs",
        url: "#schoolPrograms",
      },
      {
        title: "Cooking Camps",
        url: "#cookingCamps",
      },
      {
        title: "Private Events",
        url: "#privateEvents",
      },
      {
        title: "Online Classes",
        url: "#onlineClasses",
      }
    ],
  },
  {
    title: `Own a Franchise`,
    subItems: [
      {
        title: "Own a Franchise",
        url: "/franchise",
      },
      {
        title: "Find Locations Nationwide",
        url: "#findLocationsNationwide",
      }
    ],
  },
  {
    title: `Work with us`,
    url: "#work",
  },
  {
    title: `Sticky Notes`,
    url: "/sticky-notes",
  },
  {
    title: `Shop`,
    url: "#shop",
  },
  {
    title: `Contact us`,
    url: "/contact",
  },
  // {
  //   title: `School Programs`,
  //   url: '#school',
  //   subItems: [
  //     {
  //       title: 'Denver | Boulder School Programs',
  //       url: '#denver'
  //     },
  //     {
  //       title: 'Northern Chicago School Programs',
  //       url: '#chicago'
  //     },
  //     {
  //       title: 'Austin School Programs',
  //       url: '#austin'
  //     },
  //     {
  //       title: 'Northern Virginia School Programs',
  //       url: '#virginia'
  //     },
  //     {
  //       title: 'San Diego School Programs',
  //       url: '#sandiego'
  //     },
  //     {
  //       title: 'Dallas School Programs',
  //       url: '#dallas'
  //     },
  //   ]
  // },
];
