import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { ReactNode, useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
// import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
// import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
// import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
// import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { invoicesColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { Invoice, timesheet_entries } from '../../../../../types/finance.types';
import { useGetVenuesQuery } from '../../../../../services/endpoints/places/venue';
import { getCurrentUser } from '../../../../../services/helper';
import { DetailSection, InvoiceLegend, LabelText, TimesheetIntro } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { useDataContext } from '../../../../../context';
import { useGetCurrentEntriesQuery } from '../../../../../services/endpoints/finance/currentEntries';
import { isLocal } from '../../../../../utils/consts';
import { TimesheetEntry } from '../../../../../types/place.types';
import { getCurrentTimesheetColumns, masterTimesheetColumns } from '../../../../../utils/consts/columnDefs/timesheetColumns';

const defaultScope = 'open';
const currentUser = getCurrentUser();

export const CurrentEntriesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const [scopes, setScopes] = useState<Scopes>({});
  const isLocalUser = isLocal(currentUser?.user_type_id!);

  const [userId, setUserId] = useState<number>(isLocalUser ? 0 : (currentUser?.id ?? 0));

  const { data, error, isLoading,isFetching } = useGetCurrentEntriesQuery(userId, {skip: !userId});
  const [timesheetEntries, setTimesheetEntries] = useState<timesheet_entries[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = (currentUser?.isGlobal);
  const isFinanceAdmin = (currentUser?.canSeeFinanceMenu);
  // const {data: venues, error: venuesError, isLoading: venuesLoading} = useGetVenuesQuery({data: {orderBy: {field: 'title', order: 'asc'}}});





  const sidebars = [<TimesheetIntro />];

  if (isFinanceAdmin && ["open", "draft", "sent", "late"].includes(filters.scope ?? "")) {
    sidebars.unshift(
      <DetailSection title="Summary">
        <LabelText label="Total Amount Due:" >
          {/* <CurrencyCell value={String(data?.totals?.amount_due ?? 0)} /> */}
        </LabelText>
      </DetailSection>
    );
  }
  
  useEffect(() => {
    if (!error && !isLoading && data && data.ytd) {
      // if (data.scopes) {
      //   setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
      //   setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
      //     title: title,
      //     key: scope,
      //   }))));
      // }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data?.ytd?.timesheetEntries) {
      setTimesheetEntries(data.ytd?.timesheetEntries);
    }
    console.log("================================")
    console.log("================================")
    console.log("================================")
    console.log(data?.ytd?.timesheetEntries)
    console.log(isLocalUser)
    console.log("================================")
    console.log("================================")
  }, [data]);

  // const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     data: {
  //       ...prev!.data,
  //       filterBy,
  //     }
  //   }))
  // };

  return (
    <div className="py-2">
      <div className='d-lg-none '>
             <h1>Current Entries </h1>

      </div>
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Current Entries`}
        columns={masterTimesheetColumns}
        data={timesheetEntries}
        count={timesheetEntries?.length}
        defaultScope={'all'}
        // scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath={'current-entries'}
        // filters={filters?.data?.filterBy}
        // filterForm={(
        //   <FilterForm
        //     fields={filterFormFields} 
        //     onApplyFilters={setFilterBy}
        //   />
        // )}
        onFiltersChanged={setFilters}
        // sidebars={sidebars}
      />
    </div>
  );
}
