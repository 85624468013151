import { ColumnDef } from "@tanstack/react-table";
import { LinkCell } from "../../../components/tables/cellComponents/LinkCell";
import { Document } from "../../../types/content.type";
import {  Stack } from "react-bootstrap";

export const trainingVideosColumns: ColumnDef<Document>[] = [
    {
      header: "ID",
      accessorKey: "id",
      cell: ({ getValue }) => (
        <LinkCell
          content={getValue<number>()}
          url={`/admin/training/videos/${getValue<number>()}`}
        />
      ),
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Category",
      accessorKey: "Category",
    },
    {
      header: "Code",
      accessorKey: "code",
    },
    {
      header: () => null,
      id: "actions",
      enableSorting: false,
      cell: ({ row }) => (
        <Stack direction="horizontal" gap={2}>
          <LinkCell
            content="View"
            url={`/admin/training/videos/${row.original.id}`}
            underline
          />
          <LinkCell
            content="Edit"
            url={`/admin/training/videos/${row.original.id}/edit`}
            underline
          />
          <LinkCell
            content="Delete"
            url={`/admin/training/videos/${row.original.id}/delete`}
            underline
          />
        </Stack>
      ),
    },
  ];