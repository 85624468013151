import "./RecipesFilters.css";
import RecipeFiltersItem from "./RecipesFiltersItem";
import { Field, Formik, Form as FormikForm } from "formik";

interface RecipeFilter {
  title: string;
  type?: string;
  field: string;
  values?: { name: string; value: string }[];
}

const clearPayload = (payload: any) => {
  const newPayload = { ...payload };
  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const element = newPayload[key];
      if (
        element === undefined ||
        element === null ||
        element === "" ||
        (Array.isArray(element) && element.length === 0)
      ) {
        delete newPayload[key];
      }
    }
  }
  return newPayload;
}

const buildPayload = (
  payload: { [key: string]: any },
  filters: RecipeFilter[]
): { operator: string; field: string; value: string | string[] }[] => {
  const cleanPayload = clearPayload(payload);
  let newPayload: { operator: string; field: string; value: string | string[] }[] = [];
  for (const key in cleanPayload) {
    if (Object.prototype.hasOwnProperty.call(cleanPayload, key)) {
      const value = cleanPayload[key];
      const filter = filters.find((filter) => filter.field === key);
      if (filter) {
        if (filter.type === "input" || filter.type === "checkbox") {
          newPayload.push({
            operator: "contains",
            field: key,
            value: value,
          });
        } else if (filter.type === "slide") {
          newPayload.push(
            {
              operator: "greater_equal_than",
              field: key,
              value: value[0],
            },
            {
              operator: "lesser_equal_than",
              field: key,
              value: value[1],
            }
          );
        }
      }
    }
  }
  return newPayload;
};

interface RecipesFiltersProps {
  handlePageChange: (page: number, filterBy?: { operator: string; field: string; value: string | string[] }[]) => void;
}

const RecipesFilters: React.FC<RecipesFiltersProps> = ({
  handlePageChange,
}) => {
  const filters: RecipeFilter[] = [
    { title: "Keyword", field: "title", type: "input" },
    { title: "Ingredient", field: "ingredients_field", type: "input" },
    { title: "Author", field: "author", type: "input" },
    {
      title: "Meal Type",
      type: "checkbox",
      field: "meal_type",
      values: [
        { name: "Snacks", value: "snacks" },
        { name: "Breakfast", value: "breakfast" },
        { name: "Main", value: "main" },
        { name: "Beverage", value: "beverage" },
        { name: "Side", value: "side" },
        { name: "Sauce/Condiments", value: "sauce" },
        { name: "Dessert", value: "dessert" },
      ],
    },
    {
      title: "Recipe Type",
      field: "recipe_type",
      type: "checkbox",
      values: [
        { name: "Recipe", value: "recipe" },
        { name: "Family Meal Plan", value: "meal" },
      ],
    },
    {
      title: "Geographic Region",
      field: "cuisine_type",
      type: "checkbox",
      values: [
        { name: "Africa", value: "africa" },
        {
          name: "Australia & Pacific Islands",
          value: "australia_and_pacific_islands",
        },
        { name: "Caribbean", value: "caribbean" },
        { name: "Central America", value: "central_america" },
        { name: "Central Asia", value: "central_asia" },
        { name: "Central Europe", value: "central_europe" },
        { name: "East Asia", value: "east_asia" },
        { name: "Eastern Europe", value: "eastern_europe" },
        {
          name: "Middle East & North Africa",
          value: "middle_east_and_north_africa",
        },
        { name: "North America", value: "north_america" },
        { name: "Northern Europe", value: "northern_europe" },
        { name: "South America", value: "south_america" },
        { name: "South Asia", value: "south_asia" },
        { name: "Southeast Asia", value: "southeast_asia" },
        { name: "Western Europe", value: "western_europe" },
      ],
    },
    {
      title: "Season",
      field: "season",
      type: "checkbox",
      values: [
        { name: "Spring", value: "spring" },
        { name: "Summer", value: "summer" },
        { name: "Winter", value: "winter" },
        { name: "Fall", value: "fall" },
      ],
    },
    {
      title: "Cook Method",
      field: "cook_method",
      type: "checkbox",
      values: [
        { name: "Full Kitchen", value: "full_kitchen" },
        { name: "Microwave", value: "microwave" },
        { name: "No Cook", value: "no_cook" },
      ],
    },
    { title: "Prep Time (Minutes)", type: "slide", field: "prep" },
    { title: "Cook Time (Minutes)", type: "slide", field: "cook" },
    { title: "Serving Size", type: "slide", field: "serving" },
  ];

  const initialValues: { [key: string]: string } = filters
    .filter((filter) => filter.field)
    .reduce(
      (obj, filter) => ({
        ...obj,
        [filter.field ?? ""]:
          filter.type === "checkbox" || filter.type === "slide" ? [] : "",
      }),
      {}
    );

  const handleSubmit = (values: { [key: string]: any }) => {
    const payload = buildPayload(values, filters);
    handlePageChange(1, payload);
  };

  return (
    <div className="recipes-filters">
      <div className="d-flex flex-column align-items-center align-items-sm-start">
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue }) => (
              <FormikForm onSubmit={handleSubmit} style={{ width: "100%" }}>
                {filters.map((filter, index) => {
                  return (
                    <RecipeFiltersItem
                      key={index}
                      title={filter.title}
                      type={filter.type ? filter.type : undefined}
                      field={filter.field}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      checkboxValues={filter.values ? filter.values : undefined}
                    />
                  );
                })}
              </FormikForm>
            )}
          </Formik>
        </ul>
      </div>
    </div>
  );
};

export default RecipesFilters;