import { ColumnDef } from "@tanstack/react-table";
import { LinkCell } from "../../../components/tables/cellComponents/LinkCell";
import { Document } from "../../../types/content.type";
import {  Stack } from "react-bootstrap";

export const contentVideosColumns: ColumnDef<Document>[] = [
    {
      header: "ID",
      accessorKey: "id",
      cell: ({ getValue }) => (
        <LinkCell
          content={getValue<number>()}
          url={`/admin/training/videos/${getValue<number>()}`}
        />
      ),
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Vimeo",
      accessorKey: "vimeo_id",
    }
    
  ];