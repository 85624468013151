import { Button } from "react-bootstrap";
import { Recipe } from "../../../types/lessonPlan.type";
import { FaBlender } from "react-icons/fa";
import { generateFileUrlSegment } from "../../../utils/utils";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface RecipeSurpriseProps {
    recipe: Recipe;
    scrollToRecipe: () => void;
}

const RecipeSurprise: React.FC<RecipeSurpriseProps> = ({ recipe, scrollToRecipe }) => {
    const featuredIngredientImage = recipe.featured_ingredient?.image_file_name
      ? `${generateFileUrlSegment(
          recipe.featured_ingredient.id,
          recipe.featured_ingredient.s3_base_url
        )}/${recipe.featured_ingredient.image_file_name}`
      : null;
    return (
      <>
        <div>
          <div className="food-facts">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="text-info fw-bolder mb-0">
                Surprise Ingredient: {recipe.featured_ingredient?.name}
              </h3>
              <Button
                className="recipe-button"
                variant="outline-primary"
                onClick={scrollToRecipe}
              >
                <FaBlender className="button-icon" />
                Back to Recipe
              </Button>
            </div>
            {featuredIngredientImage && (
              <>
                <img
                  src={featuredIngredientImage}
                  alt={recipe.featured_ingredient?.name}
                  loading="lazy"
                  className="image-recipe-fun-facts"
                />
                <div className="subheader tiny text-left">
                  Photo by {recipe.featured_ingredient?.image_source}
                </div>
              </>
            )}
            <SafeHTMLDisplay
              htmlContent={recipe.featured_ingredient?.content || ""}
            />
          </div>
        </div>
        <div className="dots recipe"></div>
      </>
    );
};

export default RecipeSurprise;