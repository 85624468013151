export const seasonValues: { [key: string]: string } = {
    spring: 'Spring',
    summer: 'Summer',
    winter: 'Winter',
    fall: 'Fall'
}

export const cuisineTypes: { [key: string]: string } = {
    africa: 'Africa',
    australia_and_pacific_islands: 'Australia and Pacific Islands',
    east_asia: 'East Asia',
    western_europe: 'Western Europe',
    southeast_asia: 'Southeast Asia',
    north_america: 'North America',
    south_america: 'South America',
    central_asia: 'Central Asia',
    central_america: 'Central America',
    caribbean: 'Caribbean',
    eastern_europe: 'Eastern Europe',
    middle_east_and_north_africa: 'Middle East and North Africa',
    south_asia: 'South Asia',
    central_europe: 'Central Europe',
    northern_europe: 'Northern Europe'
};

export const cookMethods: { [key: string]: string } = {
    full_kitchen: 'Full Kitchen',
    microwave: 'Microwave',
    no_cook: 'No Cook',
};

export const mealTypes: { [key: string]: string } = {
    snacks: 'Snacks',
    breakfast: 'Breakfast',
    main: 'Main',
    beverage: 'Beverage',
    side: 'Side',
    sauce: 'Sauce',
    dessert: 'Dessert',
};

export const recipeTypes: { [key: string]: string } = {
    standard_recipe: 'Standard Recipe',
    recipe_of_the_week: 'Recipe of the Week',
    first_class_recipe: 'First Class Recipe',
    live: 'Live',
};