import { KitchenSkill } from "../../../types/content.type";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface RecipeSkillsProps {
  recipe?: Recipe;
  lessonPlan?: LessonPlan;
}

const RecipeSkills: React.FC<RecipeSkillsProps> = ({ recipe, lessonPlan }) => {
  const kitchenSkills: KitchenSkill[] = [];
  if (lessonPlan) {
    lessonPlan.recipes.forEach((recipe) => {
      recipe.kitchen_skills.forEach((skill) => {
        kitchenSkills.push(skill);
      });
    });
  } else if (recipe) {
    recipe?.kitchen_skills.forEach((skill) => {
      kitchenSkills.push(skill);
    });
  }
  return (
    <>
      <div>
        <h3 className="text-info fw-bolder mb-3">Fun-Da-Mentals Kitchen Skills</h3>
        <ul role="list" className="w-list-unstyled">
          {kitchenSkills.map(
            (skill, index) =>
              skill.description && (
                <li className="kitchen-skill-item" key={`skill-${skill.id}`}>
                  <strong>{skill.name} </strong>:{" "}
                  <SafeHTMLDisplay htmlContent={skill.description} />
                </li>
              )
          )}
        </ul>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeSkills;
