import { SimpleButton } from "../../../../components/buttons/SimpleButton";
import { Child, useGetUserProfileQuery } from "../../../../services/endpoints/people/user";
import { getCurrentUser } from "../../../../services/helper";
import { Course } from "../../../../types/course.type";

interface CourseRegisterButtonProps {
  course: Course;
  addChild: () => void;
  registerCourse: () => void;
  userChildren?: Child[];
}

function isCourseSoldOut(course: Course): boolean {
  const capacity = +course.enrollment.split("/")[1];
  const enrollments = +course.enrollment.split("/")[0];
  return (enrollments >= capacity);
};

function formatDate(date: Date): string {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const CourseRegisterButton: React.FC<CourseRegisterButtonProps> = ({
  course,
  addChild,
  userChildren,
  registerCourse,
}) => {
  const currentUser = getCurrentUser();
  let buttonLabel = "Register Now";
  const isSoldOut = isCourseSoldOut(course);
  const hasStarted = course.registration_starts_on ? new Date(course.registration_starts_on) < new Date() : true;

  if (!hasStarted) {
    buttonLabel = `Coming Soon! - ${formatDate(
      new Date(course.registration_starts_on!)
    )}`;
  } else if (isSoldOut) {
    buttonLabel = "Join Waitlist";
  } else if (userChildren?.length === 0) {
    buttonLabel = "Add a Child To Register";
  } else if (!currentUser) {
    buttonLabel = "Sign Up";
  }

  return (
    <>
      <span style={{ color: "#B81F69" }}>
        {!currentUser && (
          <>
            Sign up and add children to your account to register for sessions!
          </>
        )}
        {userChildren?.length === 0 && (
          <>
            You don’t have any children tied to your account to enroll. Add at
            least one child using the button below and then you can enroll them
            in this class and more!
          </>
        )}
        {isSoldOut && (
          <>
            Sold Out! <span className="fw-bold">JOIN THE WAITLIST</span>
          </>
        )}
      </span>
      <SimpleButton
        type="button"
        className="mt-3"
        variant="primary"
        width={320}
        onClick={() => {
          if (userChildren?.length === 0) {
            addChild();
          }
          if (hasStarted) {
            registerCourse();
          }
        }}
      >
        {buttonLabel}
      </SimpleButton>
    </>
  );
};

export default CourseRegisterButton;
