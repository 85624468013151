import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { trainingVideo } from '../../../types/trainingVideos.type';
import { sfcApi } from '../../api';
export interface ContentVideoCreateUpdateValues {
    id?: number;
    name: string;
    code:string;
  }
export const trainingVideoApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getContentVideos: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `videos${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getContentVideoSync: builder.query<GetQueryResponse, GetQueryParams | void>({
      query:() => `videos/sync`,
    }),

  }),
});

export const {
  useGetContentVideosQuery,
  useLazyGetContentVideoSyncQuery,

 
} = trainingVideoApi;