import { Col, Row } from "react-bootstrap";
import Banner from "../../../components/banner/Banner";
import RecipesFilters from "./RecipesFilters";
import RecipesGrid from "./RecipesGrid";
import RecipePagination from "./RecipePagination";
import { useState, useEffect } from "react";
import { useGetRecipesQuery } from "../../../services/endpoints/content/recipe";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import RecipeBox from "../../../assets/images/SFC_RecipeBox_Stacked_DOTS.png";
import "./Recipes.css";
import { GetQueryParamsWithFilterArray } from "../../../types/api.type";
import LoginModal from "../../../components/modals/LoginModal";
import { useAppSelector } from "../../../services/hooks";
import { selectModal } from "../../../services/slicers/modalSlice";
import { useGetLessonPlansQuery } from "../../../services/endpoints/content/lessonPlan";

interface RecipeProps {}

const Recipes: React.FC<RecipeProps> = ({}) => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [lessonPlans, setLessonPlans] = useState<LessonPlan[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [skipRecipes, setSkipRecipes] = useState<boolean>(false);
  const [skipLessonPlans, setSkipLessonPlans] = useState<boolean>(false);
  const [filters, setFilters] = useState<GetQueryParamsWithFilterArray>({
    data: {
      entries: 9,
    }
  });
  const { data: recipesData } = useGetRecipesQuery(filters, { skip: skipRecipes });
  const { data: lessonPlansData } = useGetLessonPlansQuery(filters, { skip: skipLessonPlans });
  const showModal = useAppSelector(selectModal);

  useEffect(() => {
    let count = 0;
    if (!skipRecipes) {
      if (recipesData?.results) {
        setRecipes(recipesData.results);
      }
      if (recipesData?.count) {
        count += recipesData.count;
      }
    }
    if (!skipLessonPlans) {
      if (lessonPlansData?.results) {
        setLessonPlans(lessonPlansData.results);
      }
      if (lessonPlansData?.count) {
        count += lessonPlansData.count;
      }
    }
    setCount(count);
  }, [recipesData, lessonPlansData]);

  const handlePageChange = (
    page: number,
    filterBy?: { operator: string; field: string; value: string | string[] }[]
  ) => {
    if (filterBy) {
      const filterTypeIndex = filterBy?.findIndex((filter) => filter.field === 'recipe_type');
      if (filterTypeIndex > -1) {
        const value = filterBy[filterTypeIndex].value as string[];
        if (value.length === 1) {
          if (value.includes('meal')) {
            setRecipes([]);
            setSkipRecipes(true);
            setSkipLessonPlans(false);
          } else {
            setLessonPlans([]);
            setSkipLessonPlans(true);
            setSkipRecipes(false);
          }
        } else {
          setSkipRecipes(false);
          setSkipLessonPlans(false);
        }
        filterBy.splice(filterTypeIndex, 1);
      } else {
        setSkipRecipes(false);
        setSkipLessonPlans(false);
      }
    }
    setFilters({
      ...filters,
      data: {
        ...filters.data,
        page,
        ...(filterBy && { filterBy }),
      },
    });
    setPage(page);
  };

  
  return (
    <>
      <LoginModal show={showModal.show} />
      <div style={{ backgroundColor: "#FFF" }}>
        <Banner title="Recipes for Kids" pageTitle="Recipes for Kids" />
        <div className="section-result">
          <div className="container">
            <Row>
              <Col lg={3}>
                <RecipesFilters handlePageChange={handlePageChange} />
              </Col>
              <Col lg={9}>
                <RecipesGrid data={[...recipes,...lessonPlans]} count={count} />
                <RecipePagination
                  page={page}
                  count={count}
                  onPageClick={handlePageChange}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="section-banner wf-section">
        <div className="container align-left">
          <div className="d-flex align-items-start flex-wrap-mobile">
            <div className="blurb-image">
              <img src={RecipeBox} loading="lazy" height="160" alt="" />
            </div>
            <div className="blurb">
              <h4>Sticky Fingers Cooking Recipe Box</h4>
              <p>
                The simple, kid-friendly recipes in our Recipe Box are the
                perfect way to help your child discover the joy of cooking.
                Tasty, healthy recipes known to expand palettes and get even the
                pickiest of eaters to enjoy their veggies. Find your{" "}
                <em>flavorite</em> today!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Recipes;
