import { Button } from "react-bootstrap";
import { LessonPlan } from "../../../types/lessonPlan.type";
import { FaBlender } from "react-icons/fa";
import { Snippet } from "../../../types/website.types";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface LessonPlanTimelineProps {
    lessonPlan: LessonPlan;
    scrollToSteps: (elementId: string) => void;
    snippets?: Snippet[];
}

const LessonPlanTimeline: React.FC<LessonPlanTimelineProps> = ({ lessonPlan, scrollToSteps, snippets }) => {
    const nutFreeSnippet = snippets?.find(snippet => snippet.name === 'nut-free');
    return (<>
        <div id="timeline">
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="text-info fw-bolder">Class Timeline</h3>
                <Button
                className="recipe-button"
                variant="outline-primary"
                onClick={() => scrollToSteps('steps')}
                >
                    <FaBlender className="button-icon" />
                    Skip To STEPS
                </Button>
            </div>
            {
                nutFreeSnippet && (
                    <div className="marquee"><SafeHTMLDisplay htmlContent={nutFreeSnippet.content} /></div>
                )
            }
            <h4 className="text-info fw-bold">0-10 Minutes</h4>
            <p>{ lessonPlan.timeline_00 }</p>
            <h4 className="text-info fw-bold">10-25 Minutes</h4>
            <p>{ lessonPlan.timeline_10 }</p>
            <h4 className="text-info fw-bold">25-35 Minutes</h4>
            <p>{ lessonPlan.timeline_25 }</p>
            <h4 className="text-info fw-bold">35-45 Minutes</h4>
            <p>{ lessonPlan.timeline_35 }</p>
            <h4 className="text-info fw-bold">45-60 Minutes</h4>
            <p>{ lessonPlan.timeline_45 }</p>
        </div>
        <div className="dots recipe"></div>
    </>)
};

export default LessonPlanTimeline;