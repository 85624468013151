import { Col, Form, FormControl, Row, Stack } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditUser';
import { usaStates, userTypeNames, usTimezones } from '../../../../utils/consts';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { useDataContext } from '../../../../context';

export const UserForm = ({ touched, errors, values, setFieldValue }: StepProps) => {

  const { locationOptions } = useDataContext();

  return (
    <Row>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Field
            name="email"
            type="text"
            as={Form.Control}
            isInvalid={touched.email && !!errors.email}
          />
          <ErrorMessage name="email">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Field
            name="password"
            type="password"
            as={Form.Control}
            isInvalid={touched.password && !!errors.password}
          />
          <ErrorMessage name="password">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="confirm_password">
          <Form.Label>Password Confirmation</Form.Label>
          <Field
            name="confirm_password"
            type="password"
            as={Form.Control}
            isInvalid={touched.confirm_password && !!errors.confirm_password}
          />
          <ErrorMessage name="confirm_password">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <Field
            name="first_name"
            type="text"
            as={Form.Control}
            isInvalid={touched.first_name && !!errors.first_name}
          />
          <ErrorMessage name="first_name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <Field
            name="last_name"
            type="text"
            as={Form.Control}
            isInvalid={touched.last_name && !!errors.last_name}
          />
          <ErrorMessage name="last_name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="phone_mobile">
          <Form.Label>Mobile</Form.Label>
          <Field
            name="phone_mobile"
            type="text"
            as={Form.Control}
            isInvalid={touched.phone_mobile && !!errors.phone_mobile}
          />
          <ErrorMessage name="phone_mobile">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="user_type">
          <Form.Label>User Type</Form.Label>
          <Form.Control
            as="select"
            name="user_type"
            value={values?.user_type}
            onChange={(e) => setFieldValue!('user_type', e.target.value)}
            isInvalid={touched.user_type && !!errors.user_type}
          >
            <option>Select</option>
            {Object.entries(userTypeNames).map((item, idx) => (
              <option key={idx} value={item[0]}>
                {item[1]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {values?.user_type !== 'customer' && (
          <>
            <Form.Group className="mb-1 mb-lg-3" controlId="instructor_session_rate">
              <Form.Label>In-Person Hourly Pay Rate</Form.Label>
              <Field
                name="instructor_session_rate"
                type="number"
                as={Form.Control}
                isInvalid={touched.instructor_session_rate && !!errors.instructor_session_rate}
              />
              <ErrorMessage name="instructor_session_rate">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>

            <Form.Group className="mb-1 mb-lg-3" controlId="instructor_session_reimbursement">
              <Form.Label>In-Person Food Reimbursement Per Class</Form.Label>
              <Field
                name="instructor_session_reimbursement"
                type="number"
                as={Form.Control}
                isInvalid={touched.instructor_session_reimbursement && !!errors.instructor_session_reimbursement}
              />
              <ErrorMessage name="instructor_session_reimbursement">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>

            <Form.Group className="mb-1 mb-lg-3" controlId="instructor_online_rate">
              <Form.Label>Online Hourly Pay Rate</Form.Label>
              <Field
                name="instructor_online_rate"
                type="number"
                as={Form.Control}
                isInvalid={touched.instructor_online_rate && !!errors.instructor_online_rate}
              />
              <ErrorMessage name="instructor_online_rate">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>

            <Form.Group className="mb-1 mb-lg-3" controlId="instructor_online_reimbursement">
              <Form.Label>Online Food Reimbursement Per Class</Form.Label>
              <Field
                name="instructor_online_reimbursement"
                type="number"
                as={Form.Control}
                isInvalid={touched.instructor_online_reimbursement && !!errors.instructor_online_reimbursement}
              />
              <ErrorMessage name="instructor_online_reimbursement">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          </>
        )}

        <Stack direction="horizontal" gap={4}>
          <Form.Group className="mb-1 mb-lg-3" controlId="opts_out_of_texts">
            <Form.Label>
              <Field
                name="opts_out_of_texts"
                type="checkbox"
                as={Form.Check}
                label="Opts out of texts"
              />
            </Form.Label>
          </Form.Group>

          {/* <Form.Group className="mb-1 mb-lg-3" controlId="opts_out_of_texts">
            <Form.Label>
              <Field
                name="opts_out_of_texts"
                type="checkbox"
                as={Form.Check}
                label="Uses advanced schedulling"
              />
            </Form.Label>
          </Form.Group> */}
        </Stack>

      </Col>

      <Col xs={12} lg={6} className="pe-1">

        <Form.Group className="mb-1 mb-lg-3" controlId="timezone">
          <Form.Label>Timezone</Form.Label>
          <Form.Control
            as="select"
            name="timezone"
            value={values?.timezone}
            onChange={(e) => setFieldValue!('timezone', e.target.value)}
            isInvalid={touched.timezone && !!errors.timezone}
          >
            <option>Select</option>
            {usTimezones.map((tz, idx) => (
              <option key={idx} value={tz}>
                {tz}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage name="timezone">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="location_id">
              <Field 
                as={FormSelect}
                name="location_id"
                label="Location"
                options={locationOptions}
              />
            </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="address">
          <Form.Label>Address</Form.Label>
          <Field
            name="address"
            type="text"
            as={Form.Control}
            isInvalid={touched.address && !!errors.address}
          />
          <ErrorMessage name="address">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="address2">
          <Form.Label>Address 2 (Optional)</Form.Label>
          <Field
            name="address2"
            type="text"
            as={Form.Control}
            isInvalid={touched.address2 && !!errors.address2}
          />
          <ErrorMessage name="address2">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="city">
          <Form.Label>City</Form.Label>
          <Field
            name="city"
            type="text"
            as={Form.Control}
            isInvalid={touched.city && !!errors.city}
          />
          <ErrorMessage name="city">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="state">
          <Form.Label>State</Form.Label>
          <Form.Control
            name="state"
            as="select"
            value={values?.state}
            isInvalid={touched.state && !!errors.state}
            onChange={(e) => setFieldValue!('state', e.target.value)}
          >
            <option>Select</option>
            {usaStates.map((state, idx) => (
              <option value={state} key={idx}>
                {state}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage name="state">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="zip">
          <Form.Label>Zip</Form.Label>
          <Field
            name="zip"
            type="text"
            as={Form.Control}
            isInvalid={touched.zip && !!errors.zip}
          />
          <ErrorMessage name="zip">
            {(msg) => (
              <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>

        {values?.user_type !== 'customer' && (
          <>
            <Form.Group className="mb-1 mb-lg-3" controlId="trainee_rate">
              <Form.Label>Trainee Rate</Form.Label>
              <Field
                name="trainee_rate"
                type="number"
                as={Form.Control}
                isInvalid={touched.trainee_rate && !!errors.trainee_rate}
              />
              <Form.Text>This amount should be equal to or above the minimum wage of your territory.</Form.Text>
              <ErrorMessage name="trainee_rate">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>

            <Form.Group className="mb-1 mb-lg-3" controlId="observer_rate">
              <Form.Label>Observer Rate</Form.Label>
              <Field
                name="observer_rate"
                type="number"
                as={Form.Control}
                isInvalid={touched.observer_rate && !!errors.observer_rate}
              />
              <Form.Text>This amount should be equal to or above the minimum wage of your territory.</Form.Text>
              <ErrorMessage name="observer_rate">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>
          </>
      )}

        <Form.Group className="mb-1 mb-lg-3" controlId="active">
          <Form.Label>
            <Field
              name="active"
              type="checkbox"
              as={Form.Check}
              label="Active"
            />
          </Form.Label>
        </Form.Group>

      </Col>
    </Row>
  )
}
