import { useEffect, useState } from "react";
import { LessonPlan, Recipe, ShoppingListItem } from "../../../types/lessonPlan.type";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";
import "./RecipeIngredients.css";

function calculateQuantity(originalQuantity: number, scale: number): number {
  return Math.round(originalQuantity * scale);
};

interface LessonPlanShoppingListProps {
  lessonPlan: LessonPlan;
}

const LessonPlanShoppingList = ({ lessonPlan }: LessonPlanShoppingListProps) => {
  const [shoppingList, setShoppingList] = useState<ShoppingListItem[]>([]);
  const [scaledShoppingList, setScaledShoppingList] = useState<ShoppingListItem[]>([]);
  const [scale, setScale] = useState(1);

  const handleScaleChange = (scale: number) => {
    setScale(scale);

    const shoppingListUpdated = shoppingList.map((shoppingListItem) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(shoppingListItem.name, "text/html");
        const scalableElement = doc.querySelector(".scalable");

        if (scalableElement?.textContent) {
          const originalQuantity = parseFloat(scalableElement.textContent);
          const newQuantity = calculateQuantity(originalQuantity, scale);
          scalableElement.textContent = newQuantity.toString();
        }

        return {  ...shoppingListItem, name: doc.body.innerHTML };
    });
    setScaledShoppingList(shoppingListUpdated);
  };

  useEffect(() => {
    if (lessonPlan.shopping_list_items.length) {
        setShoppingList(lessonPlan.shopping_list_items);
        setScaledShoppingList(lessonPlan.shopping_list_items);
    }
  }, [lessonPlan]);


  return (
    <>
      <div className="ingredients" id="ingredients">
        <div className="scale mb-4">
          <div className="scale-copy">
            <div className="subheader tiny mb-0">scale</div>
          </div>
          {Array.from({ length: 7 }).map((_, index) => (
            <div
              onClick={() => handleScaleChange(index + 1)}
              className={`scale-amount scale-${index + 1}X ${
                scale === index + 1 ? "active" : ""
              }`}
              key={`scale-${index}`}
            >
              <div>{index + 1}X</div>
            </div>
          ))}
        </div>
        <h3 className="text-info fw-bolder mb-4">Shopping List</h3>
        <ul role="list" className="w-list-unstyled">
          {scaledShoppingList.map((shoppingListItem, index) => (
            <li key={`shopping-list-item-${index}`}>
              <SafeHTMLDisplay htmlContent={shoppingListItem.name} />
            </li>
          ))}
        </ul>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default LessonPlanShoppingList;
