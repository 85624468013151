import { Button } from "react-bootstrap";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import "./LessonPlanChecklist.css";
import { FaBlender } from "react-icons/fa";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";
import { FaSquareCheck } from "react-icons/fa6";
import { Snippet } from "../../../types/website.types";

interface LessonPlanChecklistProps {
    lessonPlan: LessonPlan;
    recipe?: Recipe;
    snippets?: Snippet[];
    scrollToSteps: (elementId: string) => void;
};

const LessonPlanChecklist: React.FC<LessonPlanChecklistProps> = ({ lessonPlan, recipe, scrollToSteps, snippets }) => {
    const noPrepSnippet = snippets?.find(snippet => snippet.name === 'no-prep');
    const nutFreeSnippet = snippets?.find(snippet => snippet.name === 'nut-free');
    const abcs: { title: string; class: string; content: string; small?: string }[] = [
        {
            title: 'A=',
            class: 'purple',
            content: 'Always Ask an Adult'
        },
        {
            title: 'B=',
            class: 'orange',
            content: 'Beware of Burners + Blades'
        },
        {
            title: 'C=',
            class: 'blue',
            content: 'Clean your Clappers'
        },
        {
            title: 'D=',
            class: 'tangerine',
            content: 'Do It As a Team'
        },
        {
            title: 'E=',
            class: '',
            content: 'Eat and Enjoy Everything',
            small: '(Don’t yuck my yum!)'
        },
        {
            title: 'F=',
            class: 'purple',
            content: 'Fun Is #1'
        },
    ]
  return (<>
    <div>
        <div className="d-flex align-items-center justify-content-between mb-4">
            <h3 className="text-info fw-bolder">Class Checklist</h3>
            <Button
            className="recipe-button"
            variant="outline-primary"
            onClick={() => scrollToSteps('steps')}
            >
                <FaBlender className="button-icon" />
                Skip To STEPS
            </Button>
        </div>
        <h4 className="text-info fw-bolder">Before Class</h4>
        { noPrepSnippet && <SafeHTMLDisplay htmlContent={noPrepSnippet.content} />}
        <SafeHTMLDisplay htmlContent={lessonPlan.before_class} />
        { lessonPlan.at_school && (
            <>
                <h4 className="text-info fw-bolder">At School</h4>
                <SafeHTMLDisplay htmlContent={lessonPlan.at_school} />
            </>
        )}
        <div className="indent">
            <div className="d-flex align-items-center mt-4 mb-2">
                <div className="checklist">
                    <FaSquareCheck />
                </div>
                <h5 className="text-info fw-bolder mb-0">Roster</h5>
            </div>
            <p>
                <a href="/admin/sessions" target="_blank">View/print your roster in The Dash<sup>&trade;</sup>.</a> Don&#x27;t forget to take roll at the beginning of class!
            </p>
            <div className="d-flex align-items-center mt-4 mb-2">
                <div className="checklist">
                    <FaSquareCheck />
                </div>
                <h5 className="text-info fw-bolder mb-0">Double Check for Allergies!</h5>
            </div>
            <div>Please check ALL<strong> </strong>ingredients. <a href="/admin/sessions" target="_blank">View/print the class roster with allergy information in The Dash<sup>&trade;</sup>.</a>
            {nutFreeSnippet && <SafeHTMLDisplay htmlContent={nutFreeSnippet.content} />}
            </div>
            {
                lessonPlan.welcome && (
                    <>
                        <div className="d-flex align-items-center mt-4 mb-2">
                            <div className="checklist">
                                <FaSquareCheck />
                            </div>
                            <h5 className="text-info fw-bolder mb-0">Welcome Your Students</h5>
                        </div>
                        <SafeHTMLDisplay htmlContent={lessonPlan.welcome} />
                    </>
                )
            }
            <div className="d-flex align-items-center mt-4 mb-2">
                <div className="checklist">
                    <FaSquareCheck />
                </div>
                <h5 className="text-info fw-bolder mb-0">Recite the ABCs of SFC</h5>
            </div>
            <div>
                {
                    abcs.map((abc, index) => (
                        <div key={index} className="d-flex align-items-center">
                            <div className={`text-abc ${abc.class}`}>
                                {abc.title}
                            </div>
                            <div>
                                {abc.content} {abc.small && <em>{abc.small}</em>}
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                recipe?.featured_ingredient?.uncovering_instructions && (
                    <>
                        <div className="d-flex align-items-center mt-4 mb-2">
                            <div className="checklist">
                                <FaSquareCheck />
                            </div>
                            <h5 className="text-info fw-bolder mb-0">Uncover the Surprise Ingredient</h5>
                        </div>
                        <SafeHTMLDisplay htmlContent={recipe.featured_ingredient.uncovering_instructions} />
                    </>
                )
            }
            <div className="d-flex align-items-center mt-4 mb-2">
                <div className="checklist">
                    <FaSquareCheck />
                </div>
                <h5 className="text-info fw-bolder mb-0">Say the Name of the Recipe</h5>
            </div>
            <p>{ lessonPlan.name }</p>
        </div>
    </div>
    <div className="dots recipe"></div>
  </>);
};

export default LessonPlanChecklist;