import "../FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Button, Image } from "react-bootstrap";
import About1 from "../../../assets/images/about-us.png";
import About2 from "../../../assets/images/about-us.png";
import About3 from "../../../assets/images/about-us.png";
import Dots from "../../../assets/images/dots.png";
import Inspiration from "../../../assets/images/inspiration.png";
import Innovation from "../../../assets/images/innovation.png";
import Community from "../../../assets/images/community.png";
import Impact from "../../../assets/images/impact.png";
import Trust from "../../../assets/images/trust.png";
import Recipe from "../../../assets/images/recipe-impact.png";
import Pic from "../../../assets/images/Pic.png";
import IconInstagram from "../../../assets/images/instagram.png";
import IconFacebook from "../../../assets/images/facebook.png";
import IconMail from "../../../assets/images/mail.png";
import IconTelephone from "../../../assets/images/telephone.png";
import { FaArrowRight } from "react-icons/fa6";
import { Franchise } from "../../../types/franchise";
import { SFCLocation } from "../../../types/place.types";
import Slider from "react-slick";

interface FranchiseProps {
  location: SFCLocation | undefined;
}

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

const imgSlides = [About1, About2, About3];
export const AboutUsSubpage: React.FC<FranchiseProps> = ({location}) => {
  return (
    <Container className="pb-5 text-blue ">
      <div className="col-12 d-flex about-us-container">
        <div className="col-md-5 col-sm-12 align-content-center welcome-text">
          <p className="mt-5 text-weight-700  ">
            Welcome to Sticky Fingers Cooking!
          </p>
          <h1 className="my-3  text-weight-700 text-blue">
            {location?.title}
          </h1>
          <h3>
            Inspire your kids to love healthy foods & turn your picky
            eater into a food explorer.
          </h3>

          <Button className="text-uppercase px-5 py-3 mb-5 bg-orange">
            Find Our Classes
            <FaArrowRight className="ms-2" size={22} />
          </Button>

          <div>
            <h5 className=" text-weight-700">Contact Us</h5>
            <div className="d-flex flex-nowrap ">
              <Image src={Pic} className="pic-container"></Image>
              <div>
                <h4 className="px-3 ">
                  <strong>{location?.manager?.full_name}</strong> 
                  {/* · {franchise.contactRole} */}
                </h4>
                <div className="d-flex flex-nowrap">
                  <Image
                    src={IconTelephone}
                    className="icons-contact"
                  ></Image>
                  <p className="px-3 margin-none">{location?.phone_number}</p>
                </div>
                <div className="d-flex flex-nowrap">
                  <Image
                    src={IconMail}
                    className="icons-contact"
                  ></Image>
                  <p className="px-3 margin-none">
                    {location?.manager?.email}
                  </p>
                </div>
                <div className="d-flex flex-nowrap">
                  <Image
                    src={IconFacebook}
                    className="icons-contact"
                  ></Image>
                  <p className="px-3 margin-none">
                    {/* {franchise.facebookProfile} */}
                  </p>
                </div>
                <div className="d-flex flex-nowrap">
                  <Image
                    src={IconInstagram}
                    className="icons-contact"
                  ></Image>
                  <p className="px-3 margin-min">
                    {/* {franchise.instagramProfile} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-slider mt-5 offset-min ">

          <div className="carousel-container-about-us">
            <Slider {...carouselSettings}>
              {imgSlides.map((item, index) => (
                <div key={index} className="carousel-item">
                  <Image src={item}></Image>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className=" w-100">
        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>
        <h3 className="text-align-center w-100 text-weight-700 ">
          Everywhere You Want Us to Be!
        </h3>
        <h5 className="mt-5 text-weight-700  ">
          Welcome to Sticky Fingers Cooking®, your home-grown kids'
          cooking school! We don’t have a fixed location; we are
          everywhere you want us to be. Whether it's at your school,
          camp, community center or your child’s birthday party, we
          bring the fun and education of cooking directly to you.
        </h5>
        <div className="w-100 d-flex justify-content-center">
          <Button className="button-header justify-content-center text-uppercase">
            Why we’re Grape
          </Button>
        </div>
      </div>

      <div className=" w-100">
        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>

        <h3 className="text-align-center w-100 text-weight-700 ">
          Who We Are
        </h3>

        <h5 className="text-weight-700">
          <span className="d-block pb-3">
            Hello {location?.title}! 
          </span>
          I’m {location?.manager?.full_name}, the proud owner of Sticky Fingers Cooking®
          here in the {location?.title} Community. I’m thrilled to be
          bringing our cooking school without walls directly to you!
          We don’t have a fixed location; we go directly to where kids
          are in our community—be it schools, camps, community
          centers, or parties and events.
          <span className="d-block pb-3 pt-3">
            I come from a background in the culinary arts and have
            always been passionate about community service. My family
            consists of a supportive spouse, two energetic kids, and a
            lovable dog, who have been a great source of inspiration
            and support in this journey. When I discovered Sticky
            Fingers Cooking®, I knew it was the perfect blend of my
            interests and values.
          </span>
          Sticky Fingers Cooking® resonates with me because it
          combines education and fun. Our mission to Cultivate
          ‘Cool’inary Curiosity in Kids® is something I’m genuinely
          excited to be a part of. I’m committed to nurturing young
          minds and giving back. It’s important for me to become an
          important part of our community, and I believe Sticky
          Fingers Cooking® is the perfect way to achieve this.
          <span className="d-block pb-3 pt-3">
            My goal is to make cooking fun and accessible for kids.
            I’m dedicated to making a positive impact and contributing
            to the well-being of our community. I absolutely love
            teaching kids to cook because kids eating well is
            important. It’s incredibly fulfilling to watch them
            discover new flavors and techniques.
          </span>
          And of course, I can’t resist sharing my favorite food
          pun/joke with you: “Why did the scarecrow become a
          successful chef? Because he was outstanding in his field!”.
          I’m excited to cook up some fun and delicious memories with
          children in our community because it’s Thyme to Turnip the
          Beet on What Kids Eat®! 
          <span className="d-block pb-3 pt-3">
            Feel free to reach out if you have any questions or if
            you’re interested in having Sticky Fingers Cooking® at
            your school, camp, or event.
          </span>
          Let’s get cooking {location?.title}!
        </h5>

        <div className="d-flex flex-nowrap my-5">
          <Image src={Pic} className="pic-container"></Image>
          <div className="contact-container">
            <h4 className="px-3 w-100 contact-text">
              - <strong>{location?.manager?.full_name}</strong> 
              &nbsp;·&nbsp;{/*{franchise.contactRole} */}
            </h4>
            <div className="d-flex flex-nowrap">
              <Image
                src={IconTelephone}
                className="icons-contact"
              ></Image>
              <p className="px-3 margin-none">{location?.phone_number}</p>
            </div>
            |
            <div className="d-flex flex-nowrap">
              <Image
                src={IconMail}
                className="icons-contact"
              ></Image>
              <p className="px-3 margin-none">
                {location?.manager?.email}
              </p>
            </div>
            |
            <div className="d-flex flex-nowrap">
              <Image
                src={IconFacebook}
                className="icons-contact"
              ></Image>
              <p className="px-3 margin-none">
                {/* {franchise.facebookProfile} */}
              </p>
            </div>
            |
            <div className="d-flex flex-nowrap">
              <Image
                src={IconInstagram}
                className="icons-contact"
              ></Image>
              <p className="px-3 margin-min">
                {/* {franchise.instagramProfile} */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" w-100">
        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>

        <h3 className="text-align-center w-100 text-weight-700 mb-5">
          Our Core Values + Mission
        </h3>
        <div className="w-100 d-flex core-values justify-content-center">
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image src={Trust} className="box-values-image"></Image>
            </div>
            Trust
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image
                src={Impact}
                className="box-values-image"
              ></Image>
            </div>
            Impact
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image
                src={Innovation}
                className="box-values-image"
              ></Image>
            </div>
            Innovation
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image
                src={Community}
                className="box-values-image"
              ></Image>
            </div>
            Community
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container inspiration-img">
              <Image
                src={Inspiration}
                className="box-values-image"
              ></Image>
            </div>
            Inspiration
          </div>
        </div>

        <div className="w-100 mt-5">
          <h5 className="text-weight-700 text-align-center">
            At Sticky Fingers Cooking®, we are mission focused on
            Cultivating ‘Cool’inary Curiosity in Kids®—supporting
            lifelong skills in preparing, eating, and sharing
            nutritious foods. Because it's Thyme to Turnip the Beet on
            what kids eat®.
          </h5>
        </div>

        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>

        <div className="w-100 mt-5 ">
          <div className="d-flex justify-content-center recipe-image">
            <Image src={Recipe} className="recipe-image"></Image>
          </div>
          <h3 className="text-weight-700 text-align-center mb-5">
            Our Recipe for Community Impact
          </h3>

          <h5 className="text-weight-700">
            Sticky Fingers Cooking® is dedicated to making a
            deliciously meaningful impact in our communities. With
            hundreds of thousands of students taught in thousands of
            schools and venues across the U.S., we’re proud to enrich
            children’s lives through our mobile cooking school model.
            <ul className="mt-3">
              <ol>
                <strong>Community Outreach: </strong> We offer our
                programs to a wide range of schools, libraries, camps,
                community centers, and more, spreading the joy of
                cooking and healthy eating.
              </ol>
              <ol>
                <strong>Giving Back: </strong> Giving back to our
                community is baked into our company culture. We
                proudly reinvest in the communities we serve,
                enriching lives and supporting local initiatives.
              </ol>
            </ul>
            If you’re interested in bringing Sticky Fingers Cooking®
            to your school, camp, or organizing a fun birthday party,
            get in touch with us. We look forward to creating
            delightful culinary experiences and Cultivating
            'COOL'INARY CURIOSITY in Kids® in our community. LETTUCE
            make a difference together!
          </h5>
        </div>
      </div>
    </Container>
  )
}