import { FormValues } from '../../../features/dash/views/finance/Invoices/AddEditInvoice';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Invoice, PayrollResponse } from '../../../types/finance.types';
import { sfcApi } from '../../api';
import { getCurrentUser } from '../../helper';
const currentUser = getCurrentUser();

export const currentEntriesApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentEntries: builder.query<PayrollResponse, number>({
      query: (id) => ({
        url: `current-entries${id ? '/'+id : ''}`,
      }),
      providesTags: ['CurrentEntries']
    }),
   
  }),
});

export const {
  useGetCurrentEntriesQuery,
} = currentEntriesApi;