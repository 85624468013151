import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
interface ModalState {
    show: boolean;
}
const initialState: ModalState = {
    show: false
};
const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggleModal: (state) => {
            state.show = !state.show;
        },
        showModal: (state) => {
            state.show = true;
        },
        hideModal: (state) => {
            state.show = false;
        }
    },
});
export const selectModal = (state: RootState) => state.modal;
export const { toggleModal, showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;