import { canSeeFinanceMenu, canSeeFinancials, canSeeOrdersMenu, hasDashAccess, isAdmin, isGlobal, isLocal, isSuper, isInstructor, isWebContentEditor, timezonesMapping } from '../utils/consts';
import { Caregiver, Child, User } from './endpoints/people/user';

export const isAuthenticated = (): boolean => {
  const userStr = localStorage.getItem("user");
  if (userStr) return !!JSON.parse(userStr).access_token;

  return false;
};

export const currentUserHasRole = (checkFunction: (userRole: number) => boolean): boolean => {
  return checkFunction(getCurrentUser()?.user_type_id ?? -1);
};

export const getAcessToken = (): string | null => {
  const user = getCurrentUser();
  if (user && user.access_token) return user.access_token;
  return null;
};

export const getRealUser = (): User | null => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const getCurrentUser = (): User | null => {
  const user = _getCurrentUser();
  if (user) {
    let type = user.user_type_id ?? -1;
    if (type === -1) {
      return user;
    }
    user.isWebContentEditor = isWebContentEditor(type);
    user.isInstructor = isInstructor(type);
    user.isAdmin = isAdmin(type);
    user.isSuper = isSuper(type);
    user.isLocal = isLocal(type);
    user.hasDashAccess = hasDashAccess(type);
    user.isGlobal = isGlobal(type);
    user.canSeeFinanceMenu = canSeeFinanceMenu(type);
    user.canSeeFinancials = canSeeFinancials(type);
    user.canSeeOrdersMenu = canSeeOrdersMenu(type);

    user.isLocal = isLocal(type);
    user.isGlobal = isGlobal(type);
    user.isGlobal = isGlobal(type);
    user.isGlobal = isGlobal(type);
    user.isGlobal = isGlobal(type);
  }

  return user;
};

export const _getCurrentUser = (): User | null => {
  const userStr = localStorage.getItem("mimic");
  if (userStr) return JSON.parse(userStr);

  return getRealUser();
};

export const getMimicUser = (): User | null => {
  const userStr = localStorage.getItem("mimic");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const logoutUser = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("mimic");
  window.location.href = "/";
}

export const stopMimic = () => {
  localStorage.removeItem("mimic");
  window.location.href = "/";
}

export interface LatLng {
  lat: number; 
  lng: number;
}

export interface LocationSelection {
  label: string; 
  value: string;
}

export interface SavedLocationSelection {
  label: string; 
  location: LatLng;
}

export const getPreferredLocation = (): SavedLocationSelection | null => {
  const locStr = localStorage.getItem("preferredLocation");
  if (locStr) return JSON.parse(locStr) as SavedLocationSelection;
  return null;
};

export const setPreferredLocation = (location: string): void => {
  localStorage.setItem("preferredLocation", location);
};

export const getTimeZone = () => {
  const user = getCurrentUser();
  return timezonesMapping[user?.timezone ?? 'US/Mountain']; // US-Mountain default
}

export const formatUserName = (user: Partial<User | Child | Caregiver> | undefined | null) => (user ? `${user?.first_name} ${user?.last_name}` : 'User');

export const objectToFormData = (obj: Record<string, any>, formData = new FormData(), parentKey?: string): FormData => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        // If the value is an array
        console.log('array', value);
        value.forEach((item, index) => {
          if (item instanceof File) {
            // Append each file under the same key
            formData.append(formKey, item);
          } else if (typeof item === 'object' && item !== null) {
            // Recursively handle nested objects
            objectToFormData(item, formData, `${formKey}[${index}]`);
          } else {
            // Convert other types to strings
            formData.append(formKey, String(item));
          }
        })
      } else if (value instanceof File) {
        // If the value is a File (e.g., from an input), append it directly
        formData.append(formKey, value);
      } else if (typeof value === 'object' && value !== null) {
        // Recursively handle nested objects
        objectToFormData(value, formData, formKey);
      } else if (value !== undefined) {
        // Append other types (strings, numbers, booleans, etc.)
        formData.append(formKey, String(value));
      }
    }
  }
  return formData;
}
