import { Button } from "react-bootstrap";
import { LessonPlan } from "../../../types/lessonPlan.type";
import { FaBlender } from "react-icons/fa";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface LessonPlanNotesProps {
    lessonPlan: LessonPlan;
    scrollToSteps: (elementId: string) => void;
}

const LessonPlanNotes: React.FC<LessonPlanNotesProps> = ({ lessonPlan, scrollToSteps }) => {
    return (<>
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="text-info fw-bolder">Lesson Plan Add-ins & Tidbits</h3>
                <Button
                className="recipe-button"
                variant="outline-primary"
                onClick={() => scrollToSteps('steps')}
                >
                    <FaBlender className="button-icon" />
                    Skip To STEPS
                </Button>
            </div>
            <div>
                <SafeHTMLDisplay htmlContent={lessonPlan.notes} />
            </div>
        </div>
        <div className="dots recipe"></div>
    </>)
}

export default LessonPlanNotes;