/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Badge, Button, Col, Form, InputGroup, Row, Stack } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { useNavbar } from '../../../context/DashNavbarContext';
import { HiOutlineAdjustmentsHorizontal } from 'react-icons/hi2';
import { GetQueryParams } from '../../../types/api.type';
import { titleCase } from '../../../utils/stringConversions';
import { useMyLocationContext } from '../../../context/LocationContext';
import { useDataContext } from '../../../context';
import "./CourseSearchBar.css";

interface CourseOmniSearchProps {
  onSearch?: (filter: string) => void;
  noButton?: boolean;
  initialValue?: string;
}

export const CourseOmniSearch: React.FC<CourseOmniSearchProps> = ({onSearch, noButton, initialValue}) => {

  const [globalFilter, setGlobalFilter] = useState<string>(initialValue ?? '');

  return (
    <Form.Group controlId='search'>
      <InputGroup size="sm">
        <Form.Control
          type="text"
          placeholder="Search by keyword"
          aria-label="Search"
          name="search"
          defaultValue={globalFilter}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
        />
        {!noButton &&<Button variant="primary" onClick={() => onSearch?.(globalFilter)}><BsSearch />&nbsp;SEARCH</Button>}
      </InputGroup>
      <Form.Text>Keywords can include School/Venue, Session or Theme names</Form.Text>
    </Form.Group>
  );
}

interface CourseSearchBarProps {
  filters?: GetQueryParams;
  setFilters?: React.Dispatch<React.SetStateAction<GetQueryParams>>;
}

const CourseSearchBar: React.FC<CourseSearchBarProps> = ({filters, setFilters}) => {

  const { activeNavbar, setActiveNavbar, setFilterNavContent, setFilterNavOverlap } = useNavbar();
  const { myLocation: preferredLocation } = useMyLocationContext();
  const { getLocationTitle } = useDataContext();

  const applySearchFilter = (search: string) => {
    if (search === '') return;
    setFilters?.((old) => {
      let copy = JSON.parse(JSON.stringify(old)) as GetQueryParams;
      let filterIndex = copy?.data?.filterBy?.findIndex(f => f.field === 'search') ?? -1;
      let newFilter = {field: 'search', operator: 'contains', value: search};
      if (copy.data) {
        if (copy.data.filterBy) {
          if (filterIndex !== -1) {
            copy.data.filterBy[filterIndex] = newFilter;
          }
          else {
            copy.data.filterBy.push(newFilter);
          }
        }
        else {
          copy.data.filterBy = [newFilter];
        }
      }
      else {
        copy.data = {filterBy: [newFilter]};
      }
      return copy;
    });
  }

  const clearFilter = (index: number, subIndex?: number) => {
    setFilters?.((old) => {
      let copy = JSON.parse(JSON.stringify(old)) as GetQueryParams;
      let filter = copy?.data?.filterBy?.[index];
      if (filter) {

        if (subIndex !== undefined) {
          let value = filter.value;
          let newValue: string|any[] = '';
          if (Array.isArray(value)) {
            value.splice(subIndex, 1);
            filter.value = value;
            newValue = value as any[];
          }
          else if (typeof value === 'string') {
            let values = value.split(',');
            values.splice(subIndex, 1);
            newValue = values.join(',');
            filter.value = newValue;
          }

          if (newValue?.length < 1) {
            copy.data?.filterBy?.splice(index, 1);
          }
          else {
            copy.data?.filterBy?.splice(index, 1, filter);
          }
        }
        else {
          copy.data?.filterBy?.splice(index, 1);
        }
        return copy;
      }
      return old;
    })
  }

  const FilterClearButton: React.FC<{label: string, onClick: () => void}> = ({label, onClick}) => {
    return (
      <Badge className="me-1 filter-badge blueberry"
        bg=""
        onClick={onClick}
      >{label} X</Badge>
    );
  }

  const filterButtons: Array<any> = [];
  filters?.data?.filterBy?.forEach((f, index) => {
    let value = f.value as any;
    let content;
    // for multi-select filters (locations)
    if (Array.isArray(value)) {
      content = value.forEach((v: {label: string, value: number}, idx) => {
        filterButtons.push(<FilterClearButton key={`${index}-${idx}`} label={v.label} onClick={() => clearFilter(index, idx)} />);
      });
    }
    // for quick filters (course_type)
    else if (typeof value === 'string' && value.indexOf(',') !== -1) {
      content = (value as string).split(',').forEach((v: string, idx) => {
        filterButtons.push(<FilterClearButton key={`${index}-${idx}`} label={titleCase(v)} onClick={() => clearFilter(index, idx)} />);
      });
    }
    // everything else
    else {
      let label = titleCase(String(f.value));
      // distance filter
      if (['distance'].includes(f.field)) {
        label = `~${label} Miles`;
      }
      // origin or 'my location' filter
      if (['origin'].includes(f.field)) {
        label = preferredLocation?.label ?? `(${String(f.value).split('_').join(', ')})`;
      }
      // location filter
      if (['location_id'].includes(f.field)) {
        label = getLocationTitle(String(f.value));
      }
      filterButtons.push(<FilterClearButton key={index} label={label} onClick={() => clearFilter(index)} />);
    }
  });

  return (
    <div className='course-searchbar'>
      <Form>
        <Row>
          <Col md={12} lg={8}>
            <CourseOmniSearch onSearch={applySearchFilter} initialValue={filters?.data?.filterBy?.find(f => f.field === 'search')?.value?.toString()} />
          </Col>
          <Col md={12} lg={4}>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => setActiveNavbar('filter')}
              className={`text-uppercase ${activeNavbar === 'filter' ? 'active' : ''}`}
            >
              <HiOutlineAdjustmentsHorizontal size={20} className="me-1" />
              Open Filters
            </Button>
          </Col>
        </Row>
      </Form>
      <div hidden={!(filters?.data?.filterBy?.length)}>
        <Stack direction='horizontal'>
          <span className="text-uppercas me-2">Applied Filters:</span>
          <Col>
            {filterButtons}
          </Col>
        </Stack>
      </div>
    </div>
  );
};

export default CourseSearchBar;
