import { getCurrentUser } from "../../../services/helper";
import { Recipe } from "../../../types/lessonPlan.type";

interface RecipeEquipmentProps {
  recipe: Recipe;
}

const RecipeEquipment: React.FC<RecipeEquipmentProps> = ({ recipe }) => {
  const currentUser = getCurrentUser();
  const equipments = currentUser?.hasDashAccess ? recipe.instructor_equipments_field.split('\n') : recipe.parent_equipments_field.split('\n');
  return (
    <>
      <div>
        <h3 className="text-info fw-bolder mb-3">Equipment Checklist</h3>
        <div>
          <ul role="list" className="w-list-unstyled">
            {equipments.map((equipment, index) => (
              <li className="equipment-item" key={`equipment-${index}`}>{equipment}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeEquipment;
