import { Recipe } from "../../../types/lessonPlan.type";
import { generateFileUrlSegment } from "../../../utils/utils";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface RecipeHistoryProps {
    recipe: Recipe;
};

const RecipeHistory: React.FC<RecipeHistoryProps> = ({ recipe }) => {
    const historyImage = recipe.food_history?.image_file_name
      ? `${generateFileUrlSegment(
          recipe.food_history.id,
          recipe.food_history.s3_base_url
        )}/${recipe.food_history.image_file_name}`
      : null;
    return (
      <>
        <div>
          <div className="fun-food-story">
            <h3 className="text-info fw-bolder mb-0">
              {recipe.food_history?.name}
            </h3>
            {historyImage && (
              <>
                <img
                  src={historyImage}
                  alt={recipe.food_history?.name}
                  loading="lazy"
                  className="image-recipe-fun-facts"
                />
                <div className="subheader tiny text-left">
                  Photo by {recipe.featured_ingredient?.image_source}
                </div>
              </>
            )}
            <SafeHTMLDisplay htmlContent={recipe.food_history?.content || ""} />
          </div>
        </div>
        <div className="dots recipe"></div>
      </>
    );
};

export default RecipeHistory;