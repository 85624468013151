import { Container } from "react-bootstrap";
import "./StickyNotes.css";
import { useEffect, useState } from "react";
import { useGetAllPagesQuery } from "../../services/endpoints/website/page";
import { GetQueryParams } from "../../types/api.type";
import ImageWithErrorHandling from "../../components/image/ImageWithErrorHandling";
import { Page } from "../../types/website.types";
import { BlogCard } from "./BlogCard";

const defaultScope = 'featured';

export const StickyNotesSection = () => {
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 9, orderBy: {field: 'published_on', order:"desc"} } });
  const [pages, setPages] = useState<Page[]>([]);
  const { data, error, isLoading, isFetching } = useGetAllPagesQuery(filters);

  useEffect(() => {
    if (data && data?.results) {
      setPages(data.results);
    }
  }, [data]);

  return (
    <div id="sticky-notes">
     <div className="w-100 header-image-sticky-notes">
     <h1 className="text-weight-700">Sticky Notes</h1>
     <div className="text-weight-700"><strong>Discover deliciously fun tidbits, awesome activities &amp; the latest dish!</strong></div>
    </div>

     <Container fluid className="bg-white text-blue mt-5">
        <Container className="pb-5">
        <div className="div-blog-grid">
          <div className="w-layout-grid blog-grid">
          
          {pages.map(p => <BlogCard key={p.id} page={p} />)}


          </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};
