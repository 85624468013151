import "./RecipeCard.css";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import { Button, Card } from "react-bootstrap";
import FoodPlaceholder from "../../../assets/images/food-placeholder.jpg";
import RecipeCardFavorite from "./RecipeCardFavorite";
import { Link } from "react-router-dom";
import { generateFileUrlSegment, getPrimaryRecipe, getRecipeImage } from "../../../utils/utils";

interface RecipeCardProps {
    recipe?: Recipe;
    lessonPlan?: LessonPlan;
};

const RecipeCard: React.FC<RecipeCardProps> = ({ recipe, lessonPlan }) => {
    let recipeImage: string = FoodPlaceholder;
    let primaryRecipe: Recipe | null = null;
    if (lessonPlan) {
        const findPrimaryRecipe = getPrimaryRecipe(lessonPlan);
        if (findPrimaryRecipe) {
          primaryRecipe = findPrimaryRecipe;
          recipeImage = getRecipeImage(findPrimaryRecipe, 'card') ?? FoodPlaceholder;
        }
    } else if (recipe) {
      recipeImage = getRecipeImage(recipe, 'card') ?? FoodPlaceholder;
    }
    return (
      <Card className="recipe-card" style={{ width: "100%" }}>
        <Card.Img variant="top" src={recipeImage} />
        <Card.Body className="recipe-details">
          <div className="recipe-information">
            <Card.Subtitle className="subheader text-uppercase">
              {recipe ? "Recipe" : "Family Meal Plan"}
            </Card.Subtitle>
            <Card.Title className="recipe-card-title">
              {recipe && (
                <Link to={`/recipes/${recipe.id}`}>{recipe.title}</Link>
              )}
              {
                lessonPlan && (
                  <Link to={`/lesson-plans/${lessonPlan.id}`}>
                    {lessonPlan.name}
                  </Link>
                )
              }
            </Card.Title>
            <Card.Text className="recipe-author">by {recipe?.author || primaryRecipe?.author}</Card.Text>
          </div>
          <RecipeCardFavorite recipe={recipe} lessonPlan={lessonPlan} />
        </Card.Body>
      </Card>
    );
};

export default RecipeCard;