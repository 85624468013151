import { Recipe } from "../../../types/lessonPlan.type";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface RecipeSubstitutionsProps {
  recipes: Recipe[];
}

const RecipeSubstitutions: React.FC<RecipeSubstitutionsProps> = ({
  recipes,
}) => {
  return (
    <>
      <div>
        <h3 className="text-info fw-bolder mb-4">
          Food Allergen Substitutions
        </h3>
        {
          recipes.map((recipe, index) => (
            <div key={`recipe-substitution-${index}`}>
              <h4 className="text-info fw-bold mb-2">{recipe.title}</h4>
              <SafeHTMLDisplay htmlContent={recipe.substitutions} />
            </div>
          ))
        }
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeSubstitutions;
