import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { useGetCoursesQuery } from '../../../../../services/endpoints/schedule/course';
import { Course } from '../../../../../types/course.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { needsInstructorColumns } from '../../../../../utils/consts/columnDefs/sessionColumns';
import { programTypes, sessionStatus, sessionTypes } from '../../../../../utils/consts/schedule/sessions';
import { getCurrentUser } from '../../../../../services/helper';
import { useDataContext } from '../../../../../context';

const defaultScope = 'needs_instructors';
const currentUser = getCurrentUser();

export const NeedsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading, isFetching } = useGetCoursesQuery(filters);
  const [courses, setCourses] = useState<Course[]>([]);

  const filterFormFields: FilterField[] = [
    {
      name: 'title',
      element: (
        <SearchField name="title" label="Session" placeholder="Search session" />
      )
    },
    {
      name: 'location_id',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <FormSelect
          name="status"
          label="Status"
          options={Object.entries(sessionStatus).map((status) => ({ value: status[0], label: status[1] }))}
        />
      ),
    },
    {
      name: 'course_type',
      element: (
        <FormSelect
          name="course_type"
          label="Session Type"
          options={Object.entries(sessionTypes).map((type) => ({ value: type[0], label: type[1] }))}
        />
      ),
    },
    {
      name: 'is_online',
      element: (
        <FormSelect
          name="is_online"
          label="Program Type"
          options={Object.entries(programTypes).map((type) => ({ value: Number(type[0] === 'online'), label: type[1] }))}
        />
      ),
      operator: 'equals',
    },
    {
      name: 'day_of_week',
      label: 'Day of Week',
    },
    {
      name: 'starts_from',
      id: 'starts_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="starts_from" label="Start Date" />
      )
    },
    {
      name: 'starts_to',
      id: 'starts_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="starts_to" />
      )
    },
    {
      name: 'ends_from',
      id: 'ends_on',
      operator: 'greater_equal_than',
      element: <DateField name="ends_from" label="End Date" />
    },
    {
      name: 'ends_to',
      id: 'ends_on',
      operator: 'lesser_equal_than',
      element: <DateField name="ends_to" />
    },
  ];

  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading schedule needs', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
        setCourses(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Available Sessions`}
        columns={needsInstructorColumns}
        data={courses}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters?.data?.filterBy}
        indexDownloadPath='courses'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
