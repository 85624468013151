import { sfcApi } from '../../api';
import { FormValues as RegisterFormValues } from '../../../features/user/Register';
import { FormValues as AddChildFormValues } from '../../../features/user/AddChild';
import { GetQueryResponse } from '../../../types/api.type';
import { FormValues as CreateUserFormValues, UpdateFormUserSFC } from '../../../features/dash/views/people/AddEditUser';
import { Course } from '../../../types/course.type';

export type UserType = 'customer' | 'instructor' | 'admin' | 'global_admin' | 'super_admin' | 'supreme_admin' | 'web_and_content_editor' | 'location_admin';

export type SFCLocation = {
  id: number;
  title: string;
  slug: string;
  tax_id_number: string;
  stripe_pk_key: string;
  stripe_sk_key: string;
  school_programs_copy: string;
  camps_copy: string;
  ee_id: number;
  uuid: string;
  scheduling_email: string;
  extension: number;
  latitude: string;
  longitude: string;
  zoom: number;
  hotline: string;
  is_active: true;
  primary_city: string;
  invoicing_email: string;
  business_entity_name: string;
  phone_mobile?: string;
  createdAt: string;
  updatedAt: string;
  managerId: number;
};

export type User = {
  isWebContentEditor: boolean;
  isInstructor: boolean;
  isAdmin: boolean;
  isSuper: boolean;
  isLocal: boolean;
  hasDashAccess: boolean;
  canSeeFinanceMenu: boolean;
  canSeeFinancials: boolean;
  canSeeOrdersMenu: boolean;
  isGlobal: boolean;
  id: number;
  createdAt?: string;
  first_name: string;
  last_name: string;
  full_name?: string;
  username?: string;
  greeting: string;
  phone: string;
  email: string;
  location_id: number;
  location?: SFCLocation;
  user_type: UserType;
  user_type_id: number;
  active: boolean;
  access_token?: string;
  roles?: string[];
  children?: Child[];
  orders?: Order[];
  city: string;
  state: string;
  zip: string;
  address: string;
  address2: string;
  phone_evening: string;
  phone_mobile: string;
  latitude: string;
  longitude: string;
  instructor_session_rate: number;
  instructor_session_reimbursement: number;
  instructor_online_rate: number;
  instructor_online_reimbursement: number;
  trainee_rate: number;
  timezone: string;
  password: string;
  observer_rate: number;
  uses_advanced_scheduling?: boolean;
};


export type Caregiver = {
  id?: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  relation: string;
  pickup_allowed: boolean;
  receive_auto_notifications: boolean;
  is_emergency_contact: boolean;
  child_caregivers?: {
    relation: string;
    pickup_allowed: boolean;
    receive_auto_notifications: boolean;
    is_emergency_contact: boolean;
  };
};

export type Allergy = {
  id?: number;
  allergy: string;
  severity: string;
  details: string;
  child?: Child;
  child_id?: number;
  created_at?: string;
  updated_at?: string;
};

export type Waiver = {
  id?: number;
  course: Course;
  child_first_name?: string;
  child_last_name?: string;
  parent_first_name?: string;
  parent_last_name?: string;
  parent_email_address?: string;
  parent_mobile_number?: string;
  created_at?: string;
  updated_at?: string;
};

export type Child = {
  parent: Parent;
  id?: number;
  first_name: string;
  last_name: string;
  full_name?: string;
  dob: string;
  dob_formatted: string;
  grade_level: string;
  gender: string;
  emotional_needs: string;
  origination: string;
  allergies: Allergy[];
  social_emotional_needs: string;
  parent_guardian: string;
  emergency_contacts: string;
  caregivers: Caregiver[];
  pickup_type: string;
  pickup_other: string;
  agree_to_terms: boolean;
};
export interface Parent {
  id:           number;
  email:        string;
  name:         string;
}
export type Order = {
  id: number;
  date: string;
  session: string;
  total: number;
};

export type UserCredentials = {
  username: string;
  password: string;
  email?: string;
};

export type AuthResponse = {
  user: User;
};

export type FilterQueryBody = {
  filterBy?: { operator: string; field: string; value: string|number|boolean }[];
  orderBy?: { field: string; order: string };
  page?: number;
  entries?: number;
};

export type GetQueryParams = {
  data?: FilterQueryBody;
  scope?: string;
};

export type Scopes = {
  [key: string]: string;
}

const setLocalStorageUser = (response: AuthResponse) => {
  if (response.user.access_token) {
    localStorage.setItem('user', JSON.stringify(response.user));
  }
  return response;
};

export const userApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `users/users${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getAdmins: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `users/admins${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Admins'],
    }),
    getAdmin: builder.query<
      { admin : User & { children: Partial<User>[]; orders: Order[] }},
      number
    >({
      query: (id) => `users/admin/${id}`,
    }),
    getColleaguesSimple: builder.query<User[], {location_ids: Array<number>}|undefined>({
      query: (data) => ({
        url: `users/colleagues/simple`,
        method: 'POST',
        body: data,
      }),
    }),
    getInstructors: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `users/instructors${
          params?.scope ? `?scope=${params.scope}` : ''
        }`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Instructors'],
    }),
    getInstructor: builder.query<
      { instructor : User & { children: Partial<User>[]; orders: Order[] }},
      number
    >({
      query: (id) => `users/instructor/${id}`,
    }),
    register: builder.mutation<AuthResponse, RegisterFormValues>({
      query: (data) => ({
        url: 'users/register',
        method: 'POST',
        body: data,
      }),
      transformResponse: setLocalStorageUser,
    }),
    login: builder.mutation<AuthResponse, UserCredentials>({
      query: (creds) => ({
        url: 'users/signin',
        method: 'POST',
        body: creds,
      }),
      transformResponse: setLocalStorageUser,
    }),
    createUser: builder.mutation<{ user: User }, CreateUserFormValues>({
      query: (data) => ({
        url: 'users/create-sfc',
        method: 'POST',
        body: {
          ...data,
          location_id: Number(data.location_id),
          active: Number(data.active)
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Instructors', id }, 'Instructors', { type: 'Admins', id }, 'Admins']
    }),
    updateSFC: builder.mutation<{ user: User }, UpdateFormUserSFC>({//TODO: not use ANY, we have to use the correct type
      query: (data) => {
        const { id } = data;
        delete data.id;
        // delete data.opts_out_of_texts;
        // delete data.uses_advanced_scheduling;
        // delete data.children;
        // delete data.full_name;
        // delete data.user_type_id;
        // delete data.location
        
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log(data)
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        console.log("-------------------------------------------")
        const newBody= {
            first_name: data?.first_name,
            last_name: data?.last_name,
            admin_level:data?.admin_level,
            email: data?.email,
            password: data?.password,
            confirm_password: data?.confirm_password,
            phone_mobile: data?.phone_mobile,
            address: data?.address,
            address2:data?.address2,
            city: data?.city,
            state: data?.state,
            zip: data?.zip,
            timezone: data?.timezone,
            user_type: data?.user_type,
            opts_out_of_texts: data?.opts_out_of_texts,
            location_id: Number(data.location_id),
            active: Number(data.active)
          };
        if(!data.address2 || data.address2=="" ){
          delete newBody.address2;
        }
        if(data.user_type=="admin"){
          delete newBody.opts_out_of_texts
        }
        return {
          url: `users/update-sfc/${id}`,
          method: 'PUT',
          body:newBody
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Instructors', id }, 'Instructors', { type: 'Admins', id }, 'Admins']
    }), 
    updateUser: builder.mutation<AuthResponse, Partial<RegisterFormValues>>({
      query: (data) => ({
        url: 'users/update',
        method: 'PUT',
        body: data,
      }),
      transformResponse: setLocalStorageUser,
    }),
    forgotPassword: builder.mutation<{ message: string }, string>({
      query: (arg) => `/users/forgotPassword?email=${arg ? arg : ''}`,
    }),
    resetPassword: builder.mutation<
      { message: string },
      { password: string; token: string }
    >({
      query: (arg) => ({
        url: `/users/resetPassword`,
        method: 'POST',
        body: arg,
      }),
    }),
    mimic: builder.mutation<AuthResponse, number>({
      query: (userId) => ({
        url: `users/mimic`,
        method: 'POST',
        body: { userId },
      }),
      transformResponse: (response: AuthResponse) => {
        if (response.user.access_token) {
          localStorage.setItem('mimic', JSON.stringify(response.user));
        }
        return response;
      },
    }),
    addChild: builder.mutation<Child, AddChildFormValues>({
      query: (data) => ({
        url: 'children/create',
        method: 'POST',
        body: data,
      }),
    }),
    updateChild: builder.mutation<Child, Partial<AddChildFormValues>>({
      query: (data) => ({
        url: `children/update/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    getUserProfile: builder.query<{ user: User }, GetQueryParams | void>({
      query: () => `users/profile`,
      transformResponse: (response: { user: User }) => {
        response.user?.children?.forEach((child, childIndex) => {
          child.caregivers.forEach((c, cgIndex) => {
            if (response.user.children) {
              let cc = c.child_caregivers;
              delete c.child_caregivers;
              response.user.children[childIndex].caregivers[cgIndex] = {
                ...c,
                ...cc,
              };
            }
          });
        });
        return response;
      },
    }),
    getCaregivers: builder.query<Partial<Caregiver>[], void>({
      query: () => `caregivers/forUser`,
      transformResponse: (response: { caregivers: Partial<Caregiver>[] }) => {
        return response.caregivers ?? [];
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetColleaguesSimpleQuery,
  useGetAdminsQuery,
  useGetAdminQuery,
  useGetInstructorsQuery,
  useGetInstructorQuery,
  useRegisterMutation,
  useCreateUserMutation,
  useUpdateSFCMutation,
  useUpdateUserMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useMimicMutation,
  useAddChildMutation,
  useUpdateChildMutation,
  useGetUserProfileQuery,
  useGetCaregiversQuery,
} = userApi;
