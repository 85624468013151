import { FaFacebookF, FaTwitter, FaPinterestP, FaPrint } from "react-icons/fa";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import "./RecipeSocial.css";
import RecipeCardFavorite from "../recipes/RecipeCardFavorite";
import { Button } from "react-bootstrap";
import { downloadIndex } from "../../../services/api";

interface RecipeSocialProps {
    recipe?: Recipe;
    lessonPlan?: LessonPlan | null;
};

const RecipeSocial: React.FC<RecipeSocialProps> = ({ recipe, lessonPlan }) => {
  const currentUrl = window.location.href;
  const handleDownload = () => {
    downloadIndex({
      format: 'pdf',
      path: '',
      ...(recipe && {
        path: `recipes/generate-recipe/${recipe.id}`,
      }),
      ...(lessonPlan && {
        path: `lesson-plans/generate-lesson-plan/${lessonPlan.id}`,
      }),
      method: 'GET'
    });
  }
    return (
      <div className="recipe-social">
        <div>
          <a
            className="recipe-social-link ps-0"
            href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
          >
            <FaFacebookF />
          </a>
          <a
            className="recipe-social-link"
            href={`https://twitter.com/intent/tweet?text=${currentUrl}`}
          >
            <FaTwitter />
          </a>
          <a
            className="recipe-social-link"
            href={`http://pinterest.com/pin/create/button/?url=${currentUrl}`}
          >
            <FaPinterestP />
          </a>
        </div>
        <div className="d-flex">
          <RecipeCardFavorite recipe={recipe} lessonPlan={lessonPlan} />
          <Button
            className="recipe-button"
            variant="outline-primary"
            onClick={handleDownload}
          >
            <FaPrint className="button-icon" />
            Download/Print
          </Button>
        </div>
      </div>
    );
};

export default RecipeSocial;