import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import "./RecipesGrid.css";
import RecipeCard from "./RecipeCard";
import { Col, Row } from "react-bootstrap";

interface RecipesGridProps {
  data: (Recipe | LessonPlan)[];
  count: number;
}

function isRecipe(item: Recipe | LessonPlan): item is Recipe {
  return (item as Recipe).title !== undefined;
}

function isLessonPlan(item: Recipe | LessonPlan): item is LessonPlan {
  return (item as LessonPlan).name !== undefined;
}

const RecipesGrid: React.FC<RecipesGridProps> = ({ data, count }) => {

  const rows = [];
  for (let i = 0; i < data.length; i += 3) {
    rows.push(data.slice(i, i + 3));
  }

  return (
    <>
      <div className="search-results">
        <span>
          Showing <strong>{count}</strong> results
        </span>
      </div>
      <div className="recipes-grid">
        {rows.map((row, rowIndex) => (
          <Row key={rowIndex} className="mb-4">
            {row.map((item, colIndex) => (
              <Col key={colIndex} md={4}>
                {isRecipe(item) && <RecipeCard recipe={item} />}
                {isLessonPlan(item) && <RecipeCard lessonPlan={item} />}
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </>
  );
};

export default RecipesGrid;
