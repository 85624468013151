import { useEffect, useState } from "react";
import { Recipe } from "../../../types/lessonPlan.type";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";
import "./RecipeIngredients.css";
import { Ingredient } from "../../../types/content.type";

function calculateQuantity(originalQuantity: number, scale: number): number {
  return Math.round(originalQuantity * scale);
};

interface RecipeIngredientsProps {
  recipes: Recipe[];
}

const RecipeIngredients = ({ recipes }: RecipeIngredientsProps) => {
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [scaledIngredients, setScaledIngredients] = useState<Ingredient[]>([]);
  const [scale, setScale] = useState(1);

  const handleScaleChange = (scale: number) => {
    setScale(scale);

    const ingredientsUpdated = ingredients.map((ingredient) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(ingredient.name, "text/html");
        const scalableElement = doc.querySelector(".scalable");

        if (scalableElement?.textContent) {
          const originalQuantity = parseFloat(scalableElement.textContent);
          const newQuantity = calculateQuantity(originalQuantity, scale);
          scalableElement.textContent = newQuantity.toString();
        }

        return {  ...ingredient, name: doc.body.innerHTML };
    });
    setScaledIngredients(ingredientsUpdated);
  };

  useEffect(() => {
    const recipeIngredients: Ingredient[] = [];
    recipes.forEach(recipe => {
      if (recipe.ingredients.length) {
        recipeIngredients.push(...recipe.ingredients);
      }
    });
    if (recipeIngredients.length) {
      setIngredients(recipeIngredients);
      setScaledIngredients(recipeIngredients);
    }
  }, [recipes]);


  return (
    <>
      <div className="ingredients" id="ingredients">
        <div className="scale mb-4">
          <div className="scale-copy">
            <div className="subheader tiny mb-0">scale</div>
          </div>
          {Array.from({ length: 7 }).map((_, index) => (
            <div
              onClick={() => handleScaleChange(index + 1)}
              className={`scale-amount scale-${index + 1}X ${
                scale === index + 1 ? "active" : ""
              }`}
              key={`scale-${index}`}
            >
              <div>{index + 1}X</div>
            </div>
          ))}
        </div>
        <h3 className="text-info fw-bolder mb-4">Ingredients</h3>
        {
          recipes.map((recipe, index) => (
            <div key={`recipe-ingredient-${index}`}>
              <h4 className="text-info fw-bold mb-2">{recipe.title}</h4>
              <ul role="list" className="w-list-unstyled">
                {scaledIngredients.filter(ingredient => ingredient.recipe_id === recipe.id).map((ingredient, index) => (
                  <li key={`ingredient-${index}`}>
                    <SafeHTMLDisplay htmlContent={ingredient.name} />
                  </li>
                ))}
              </ul>
            </div>
          ))
        }
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeIngredients;
