import { Breadcrumb } from "react-bootstrap";
import "./Banner.css";

interface BannerProps {
    title: string;
    pageTitle: string;
    mainPage?: string;
    mainPageUrl?: string;
};

const Banner: React.FC<BannerProps> = ({
  title,
  pageTitle,
  mainPage,
  mainPageUrl,
}) => {
  return (
    <div className="section-banner-style-2 green">
      <div className="container">
        <Breadcrumb className="text-uppercase">
          <Breadcrumb.Item href={mainPageUrl ? mainPageUrl : '/'}>{ mainPage ? mainPage : 'Home' }</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>
            {pageTitle}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="break"></div>
        <h1 className="text-info fw-bold">{title}</h1>
      </div>
    </div>
  );
};

export default Banner;