import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';

import { FeaturedIngredientRecipesSaveForm } from '../../../../../types/featuredIngredients.type';
import { FeaturedIngredientForm } from './FeaturedIngredientsForm';
import { useGetFeaturedIngredientQuery,
  useCreateFeaturedIngredientMutation,
  useUpdateFeaturedIngredientMutation,
  FeaturedIngredientCreateUpdateValues
   } from '../../../../../services/endpoints/content/featuredIngredient';
import { FeaturedIngredient } from '../../../../../types/content.type';

const steps = [
  { name: 'Details', form: FeaturedIngredientForm },
];

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;
export type CreateFormValues = Overwrite<FeaturedIngredientCreateUpdateValues, {
  recipes: FeaturedIngredientRecipesSaveForm[], 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FeaturedIngredientCreateUpdateValues>>;
  touched: Partial<FormikTouched<FeaturedIngredientCreateUpdateValues>>;
  values?: FeaturedIngredientCreateUpdateValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
  featuredIngredient?: FeaturedIngredient;
}

const featuredIngredientLinksMap = (id?: number | string) => {
    return {
        edit: `/admin/content/featured-ingredients/${id}/edit`,
        detail: `/admin/content/featured-ingredients/${id}`,
        list: `/admin/content/featured-ingredients`,
    }
}

interface AddEditFeaturedIngredientProps {
  editing?: boolean;
}

export const AddEditFeaturedIngredient: React.FC<AddEditFeaturedIngredientProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createFeaturedIngredient] = useCreateFeaturedIngredientMutation();
  const [updateFeaturedIngredient] = useUpdateFeaturedIngredientMutation();
  
  const { data, error: featuredIngredientError, isLoading: featuredIngredientLoading } = 
  useGetFeaturedIngredientQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FeaturedIngredientCreateUpdateValues>({
    name: '',
    content:'',
    uncovering_instructions: '',
    image:{} as File,
    image_source:''
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
      content: Yup.string().required('content is required'),
    }),
  ];

  useEffect(() => {
    if (editing && featuredIngredientError && !featuredIngredientLoading) {
      addToast('Error while loading admin', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, featuredIngredientError, featuredIngredientLoading]);

  useEffect(() => {
    if (data?.featuredIngredient)
      setInitialValues({
        id:data?.featuredIngredient.id,
        name: data?.featuredIngredient.name,
        content: data?.featuredIngredient.content,
        uncovering_instructions: data?.featuredIngredient.uncovering_instructions,
        image_source: data?.featuredIngredient.image_source
      });

  }, [data]);

  const handleAddFeaturedIngredient = async (formValue: FeaturedIngredientCreateUpdateValues) => {
    console.log('formValue', formValue);
    try {
      if (editing) {
        console.log('editinggg')
        const res = await updateFeaturedIngredient({
          ...formValue,
          id: Number(params.id)!,
        }).unwrap();
        if (res && res.featuredIngredient) {
          navigate(featuredIngredientLinksMap(res.featuredIngredient.id!)?.list);
          addToast(`Featured Ingredient updated successfully`, 'success');
        }
      } else {
        const res = await createFeaturedIngredient(formValue).unwrap();
        if (res && res.featuredIngredient) {
          navigate(featuredIngredientLinksMap(res.featuredIngredient.id!)?.list);
          addToast('Featured Ingredient created successfully', 'success');
        } else throw new Error('A problem happened while creating Featured Ingredient');
      }
    } catch (e) {
      if(e.data && e.data.error) {
        addToast(e.data.error[0], 'error');
      }
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FeaturedIngredientCreateUpdateValues>>, 
    setTouched: (touched: FormikTouched<FeaturedIngredientCreateUpdateValues>, shouldValidate?: boolean) => void,
    values: FeaturedIngredientCreateUpdateValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        id:true,
        name: true,
        content: true,
        
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text="Back"
            color="#2B4E64"
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Featured Ingredients
          </h2>
          {steps.length > 1 && <Stepper editing steps={steps} currentStep={currentStep} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddFeaturedIngredient}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FeaturedIngredientCreateUpdateValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        ...(data && {
                          featuredIngredient: data.featuredIngredient,
                        })
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
