import { Button } from "react-bootstrap";
import { FaBlender } from "react-icons/fa";

interface LessonPlanChatterProps {
  scrollToSteps: (elementId: string) => void;
}

const LessonPlanChatter: React.FC<LessonPlanChatterProps> = ({
  scrollToSteps,
}) => {
    const questions = [
        {
            class: 'purple',
            question: 'What was your favorite part about making this recipe?',
        },
        {
            class: 'orange',
            question: 'What flavors do you taste?',
        },
        {
            class: 'blue',
            question: 'What do you remember about the country this recipe is from?',
        },
        {
            class: 'tangerine',
            question: 'What would you do differently next time you make this? What would you add or take away?',
        },
        {
            class: '',
            question: 'Do you remember why this is healthy for you?',
        },
        {
            class: 'purple',
            question: 'Did you know that it takes 12 bites of something new until your body knows if you REALLY like it or not!'
        }
    ];
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h3 className="text-info fw-bolder">Mealtime Chatter</h3>
          <Button
            className="recipe-button"
            variant="outline-primary"
            onClick={() => scrollToSteps("steps")}
          >
            <FaBlender className="button-icon" />
            Back To STEPS
          </Button>
        </div>
        <h4 className="text-info fw-bold">Questions For Kids As They Eat</h4>
        {
            questions.map((item, index) => (
                <div key={`question-${index}`} className="d-flex align-items-center">
                    <div className={`text-abc ${item.class}`}></div>
                    <div>
                        {item.question}
                    </div>
                </div>
            ))
        }
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default LessonPlanChatter;
