import { Recipe } from "../../../types/lessonPlan.type";
import { generateFileUrlSegment } from "../../../utils/utils";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface RecipeFactsProps {
  recipe: Recipe;
}

const RecipeFacts: React.FC<RecipeFactsProps> = ({ recipe }) => {
  const countryFactImage = recipe.country_fact?.image_file_name
    ? `${generateFileUrlSegment(
        recipe.country_fact.id,
        recipe.country_fact.s3_base_url
      )}/${recipe.country_fact.image_file_name}`
    : null;
  return (
    <>
      <div>
        <div className="fun-food-story">
          <h3 className="text-info fw-bolder mb-0">
            {recipe.country_fact?.name}
          </h3>
          {countryFactImage && (
            <>
              <img
                src={countryFactImage}
                alt={recipe.country_fact?.name}
                loading="lazy"
                className="image-recipe-fun-facts"
              />
              <div className="subheader tiny text-left">
                Photo by {recipe.country_fact?.image_source}
              </div>
            </>
          )}
          <SafeHTMLDisplay htmlContent={recipe.country_fact?.content || ""} />
        </div>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeFacts;
