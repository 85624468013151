import React, { useState, useEffect, useRef } from 'react';
import './RotatingImage.css';

interface RotatingImageProps {
  src: string;
}

const RotatingImage: React.FC<RotatingImageProps> = ({ src }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const isImgClicked = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 1000);

    if (imageRef.current) {
      imageRef.current.classList.remove('spin-animation');
      void imageRef.current.offsetWidth;
      imageRef.current.classList.add('spin-animation');
    }
  };

  return (
    <img
      ref={imageRef}
      src={src}
      alt="sticky fingers cooking banana graphic"
      className={`section-fruit image-rotating-food ${isVisible || isClicked ? 'spin-animation' : ''}`}
      onClick={isImgClicked}
    />
  );
};

export default RotatingImage;