import StJhons from "../../../assets/images/st-johns.png";
import SKee from "../../../assets/images/SKCEE_Logo.png";
import KirbiHall from "../../../assets/images/kirby-hall-footer-logo.png";
import NortBrook from "../../../assets/images/Northbrook_Logo.png";
import StVrain from "../../../assets/images/St-Vrain-chop_cmyk_rich-blk.png";
import BoysGirlsClub from "../../../assets/images/Boys__Girls_Clubs_of_America_logo.svg-p-500.png";
import ColoradoAcademi from "../../../assets/images/colorado-academy_logo_300x.png";
import Ymcs from "../../../assets/images/ymca-logo.png";
import Jcc from "../../../assets/images/jcc-logo-70x.png";
import Elm from "../../../assets/images/elm-grove-summer-camp-170x.png";
import Avid from "../../../assets/images/avid4adventure-logo-324x.jpg";
import Banana from "../../../assets/images/Banana.png";
import Grapes from "../../../assets/images/Grapes.png";
import Coconut from "../../../assets/images/Coconut.png";
import Orange from "../../../assets/images/Orange-Slice.png";
import Onion from "../../../assets/images/Yellow-Onion.png";
import BellPepper from "../../../assets/images/Bell-Pepper.png";
import Avocado from "../../../assets/images/Avocado-Half.png";
import Mushroom from "../../../assets/images/Mushroom.png";

import BradleyImg from "../../../assets/images/bradley-logo.png";


export interface NavItem {
  name: string;
  id: string;
}

export const navItems = [
  { name: "WHO WE ARE", id: "who-we-are" },
  { name: "SCHOOL PROGRAM INFO", id: "section2" },
  { name: "COOKING CAMP INFO", id: "section3" },
  { name: "ONLINE CLASS INFO", id: "section4" },
  { name: "PRIVATE EVENT INFO", id: "section5" },
  { name: "LIBRARY INFO", id: "section6" },
  { name: "NON-PROFIT INFO", id: "section7" },
];

export const navItemsH: NavItem[] = [
  { name: "ABOUT US", id: "section1" },
  { name: "IN THE NEWS", id: "section2" },
  { name: "TESTIMONIALS", id: "section3" },
  { name: "OUR CREW", id: "section4" },
];

export type FruitItem = {
  id: number;
  Title: string;
  description: string;
  image: string;
};

export const fruitsDataGrid : FruitItem[]=[
  { id: 1, Title: "No kitchen required", description: "All we need is a room with an outlet and proximity to a sink. We provide everything else!", image: Banana },
  { id: 2, Title: "Flexible scheduling", description: "Sessions are between six and 14 weeks. You choose the day, times and frequency!", image: Grapes },
  { id: 3, Title: "100% school safety compliant", description: "All of our instructors have background checks, fingerprinting and insurance.", image: Coconut },
  { id: 4, Title: "Easy class registration", description: "We offer free online enrollment, payment, collection, and class roster organization.", image: BellPepper },
  { id: 5, Title: "Materials included", description: "We provide all marketing materials to promote classes to your community.", image: Avocado },
  { id: 6, Title: "No cost to schools", description: "Tuition ranges from $16.50 to $22.50 per class, per child, paid by parents or guardians.", image: Orange },
  { id: 7, Title: "Discounts and scholarships", description: "Discounts are available to parents who volunteer in class. Limit two kids per family.", image: Mushroom },
  { id: 8, Title: "Online recipe collection", description: "Every family has access to our tasty online recipe collection for at home family cooking fun.", image: Onion },

]

export const dataRecentTestimonials = [
  [
    {
      image: BoysGirlsClub,
      stars:5,
      title: "kyle caragol",
      subtitle: "YMCA of Boulder Valley",
      description: '"The weekly cooking classes at all four of our YMCA sites were all very fun and very successful. It was an ideal set up! Thank you!"',
    },
    {
      image: ColoradoAcademi,
      stars:5,
      title: "Boys & Girls Clubs of Metro Denver",
      subtitle: "camille bolos",
      description: '"The kiddos loved the cooking class! There were no leftovers! That’s always a good sign. Thank you so much. Chef Dylan was lovely!"',

    },
    {
      image: Ymcs,
      title: "miguel gonzalez",
      stars:5,
      subtitle: "YMCA of Metro Denver",
      description: '"Our day campers and I had a great experience with the Sticky Fingers Cooking® program over the summer. Thank you for all that you do!"',

    },
  ],
  [
    {
      image: Jcc,
      title: "jamee smith",
      stars:5,
      subtitle: "JCC of Lake Zurich, IL",
      description: '"We love partnering with Sticky Fingers. They are wonderful at planning healthy allergen-friendly recipes that our campers look forward to preparing each week."',

    },
    {
      image: Elm,
      title: "heather scott",
      stars:5,
      subtitle: "Elm Grove Summer Camp, TX",
      description: '"It really does make a difference when all the paperwork is done ahead of time and the instructors are professional and easy to work with.  It has been a pleasure working with you!"',

    },
    {
      image: Avid,
      title: "Michelle Simonson",
      stars:5,
      subtitle: "Avid4Adventure of Mill Valley, CA",
      description: '"Thank you to Sticky Fingers Cooking® for running such an excellent cooking program. The campers, counselors, and administrators at Avid4 Adventure are grateful."',

    },
  ],
  [
    {
      image: Ymcs,
      title: "kyle caragol",
      stars:5,
      subtitle: "YMCA of Boulder Valley",
      description: '"The weekly cooking classes at all four of our YMCA sites were all very fun and very successful. It was an ideal set up! Thank you!"',

    },
    {
      image: BoysGirlsClub,
      title: "camille bolos",
      stars:5,
      subtitle: "Boys & Girls Clubs of Metro Denver",
      description: '"The kiddos loved the cooking class! There were no leftovers! That’s always a good sign. Thank you so much. Chef Dylan was lovely!"',

    },
    {
      image: Ymcs,
      title: "miguel gonzalez",
      stars:5,
      subtitle: "YMCA of Metro Denver",
      description: '"Our day campers and I had a great experience with the Sticky Fingers Cooking® program over the summer. Thank you for all that you do!"',

    },
  ],
];


export const dataCarousel = [
  [
    {
      image: StJhons,
      title: "Gloria Roggow",
      subtitle: "St. John’s Lutheran School, Denver",
      description: '"We love working with Sticky Fingers! The classes always run so smoothly, and the kids and the parents tell us how much they love the classes."',
    },
    {
      image: SKee,
      title: "Deborah Adelstein",
      subtitle: "SKCEE, Denver",
      description: '"We love working with Sticky Fingers! The classes always run so smoothly, and the kids and the parents tell us how much they love the classes."',

    },
    {
      image: KirbiHall,
      title: "helen roberts",
      subtitle: "Kirby Hall, Austin",
      description: '"I\'ve heard wonderful things about Sticky Fingers from our families. We look forward to you returning for more sessions."',

    },
  ],
  [
    {
      image: NortBrook,
      title: "marion hinken",
      subtitle: "District 28, Northbrook, IL",
      description: '"Our cooking classes were beautifully executed and organized with lots of thoughtful energy and passion behind your program."',

    },
    {
      image: StVrain,
      title: "Patricia Dykstra",
      subtitle: "St. Vrain Valley School District, CO",
      description: '"The Sticky Fingers Cooking® staff are so good with the kids—so patient. And the classes are well thought out and interactive with the students."',

    },
    {
      image: BradleyImg,
      title: "althea mcclure",
      subtitle: "Bradley International School, Denver",
      description: '"I knew by reputation how great the Sticky Fingers Cooking® program is, but experiencing it first-hand has exceeded my expectations."',

    },
  ],
];