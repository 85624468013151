import "./RecipeJokes.css";
import SFCCookingHappyChef from "../../../assets/images/sticky-fingers-cooking_happy-chef_200x.png";
import { Joke } from "../../../types/content.type";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import { useEffect, useState } from "react";

interface RecipeJokesProps {
    recipe?: Recipe;
    lessonPlan?: LessonPlan | null;
};

const RecipeJokes: React.FC<RecipeJokesProps> = ({ recipe, lessonPlan }) => {
    const [jokes, setJokes] = useState<Joke[]>([]);
    useEffect(() => {
      if (lessonPlan) {
        const lessonPlanJokes: Joke[] = [];
        lessonPlan?.recipes.forEach((recipe) => {
          if (recipe.jokes?.length) {
            lessonPlanJokes.push(...recipe.jokes);
          }
        });
        if (lessonPlanJokes.length) {
          setJokes(lessonPlanJokes);
        }
      } else if (recipe) {
        if (recipe.jokes?.length) {
          setJokes(recipe.jokes);
        }
      }
    }, [recipe, lessonPlan]);
    return (
      <>
        {
          jokes.map((joke, index) => (
            <div className="recipe-child-joke" key={`joke-${index}`}>
              <div className="joke-main">
                <img
                  src={SFCCookingHappyChef}
                  loading="lazy"
                  width="70"
                  alt=""
                  className="image-joke"
                />
                <h3 className="text-info fw-bold">{ joke.name }</h3>
                <div className="divider-35"></div>
                <div className="joke">
                  <SafeHTMLDisplay htmlContent={joke.content} />
                </div>
              </div>
            </div>
          ))
        }
      </>
    );
};

export default RecipeJokes;