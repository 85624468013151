import React, { useState } from "react";
import { Button, Col, Image, Stack } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikProps, FormikErrors, FormikTouched } from "formik";
import { FadeIn } from '../../animations/FadeIn';
import Stepper from '../../../components/stepper/Stepper';
import { FaArrowLeft } from "react-icons/fa";
import { OrderReview, OrderComplete } from './checkoutSteps';
import SFCLogo from '../../../assets/images/sticky-fingers-logo-stacked-2.png';
import { Discount, OrderPlusCost, LocationOrders, Scholarship } from "../../../types/orders.type";
import { SFCLocation } from "../../../types/place.types";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { getErrorMessage } from "../../../utils/utils";

const steps = [
  {name: 'Review/Payment', form: OrderReview},
  {name: 'Complete Registration', form: OrderComplete},
];

// TODO remove this
const stripeKey = 'pk_test_51QAD0h2KQAVxGdN5VvXRSHHMXIn0zqJ6uF0BU8RhatMWBwD5nHkFyMifN5y2HsnOFU7PL3fHjP9087mLmucTPb0f00ERwZpf7R';
const stripePromise = loadStripe(stripeKey);
stripePromise.catch((error) => {
  console.error(getErrorMessage(error));
});
  
export interface FormValues {
  id?: number;
  location: Pick<SFCLocation, 'id'|'title'>;
  orders: OrderPlusCost[];
  discounts: Discount[];
  scholarships: Scholarship[];
  promoCodeSearch?: string;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values: FormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormValues>>
  next: () => void;
}

function getValues(obl: LocationOrders, discounts: Discount[]): FormValues {
  return {
    ...obl,
    discounts,
    scholarships: [],
  }
}

interface OrderCheckoutProps {
  locationOrders: LocationOrders;
  discounts: Discount[];
}

const OrderCheckout: React.FC<OrderCheckoutProps> = ({locationOrders: obl, discounts}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues>(getValues(obl, discounts));

  return (
    <FadeIn className="w-100">
      <div className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <Stepper
          steps={steps}
          currentStep={currentStep}
          editing={false}
          onStepClick={(step) => setCurrentStep(step)}
        />
      </div>
      <div className="w-100 pt-3 d-flex justify-content-center align-items-center">
        {successful ? (
          <div
            className="mt-4 text-secondary text-center"
            style={{ minHeight: "calc(20vh + 220px)" }}
          >
            <Image
              src={SFCLogo}
              className="d-block d-lg-none w-50 mb-4 m-auto"
              alt="Sticky Fingers Logo"
            />
            <h3 className="fw-bold">
              {submitted
                ? `TODO: FIX THIS`
                : "No changes detected!"}
            </h3>
            {submitted && <Button size="sm">Done</Button>}
          </div>
        ) : (
          <Stack gap={1} style={{ maxWidth: "80%" }}>
            {stripePromise ? (
              <Elements stripe={stripePromise} options={{
                mode: 'payment',
                amount: 1000,
                currency: 'usd',
              }}>

              <Formik
                initialValues={initialValues}
                onSubmit={(form) => {}}
                enableReinitialize
              >
                {({
                  isSubmitting,
                  touched,
                  errors,
                  values,
                  setFieldValue,
                }: FormikProps<FormValues>) => (
                  <FormikForm className="text-start mt-3">
                    <div style={{ minHeight: "calc(20vh + 140px)" }}>
                      {currentStep < steps.length &&
                        React.createElement(steps[currentStep]?.form, {
                          errors,
                          touched,
                          values,
                          setFieldValue,
                          next: () => setCurrentStep(currentStep + 1),
                        })}
                    </div>
                    {currentStep === steps.length - 1 && 
                      <Col sm={4}>
                        <Stack direction="horizontal" gap={2}>
                          <Button
                            disabled={isSubmitting}
                            type="button"
                            href={`/user/orders`}
                            className="mt-3 float-end"
                            variant="primary"
                          >View Order Details
                          </Button>
                          <Button
                            disabled={isSubmitting}
                            type="button"
                            href='/'
                            className="mt-3 float-end"
                            variant="outline-primary"
                          >Back to SFC Home
                          </Button>
                        </Stack> 
                      </Col>
                    }
                  </FormikForm>
                )}
              </Formik>
              </Elements>
            ) : (<span>Failed to load Stripe</span>)}
          </Stack>
        )}
      </div>
    </FadeIn>
  );
};

export default OrderCheckout;
