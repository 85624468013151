import "./SingleRecipe.css";
import { useParams } from "react-router-dom";
import { useGetRecipeQuery } from "../../../services/endpoints/content/recipe";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import { useEffect, useState } from "react";
import Banner from "../../../components/banner/Banner";
import { Button, Col, Row } from "react-bootstrap";
import RecipeSocial from "./RecipeSocial";
import RecipeJokes from "./RecipeJokes";
import { generateFileUrlSegment, getPrimaryRecipe, getRecipeImage } from "../../../utils/utils";
import RecipeStats from "./RecipeStats";
import RecipeStory from "./RecipeStory";
import RecipeSkills from "./RecipeSkills";
import RecipeEquipment from "./RecipeEquipment";
import RecipeIngredients from "./RecipeIngredients";
import RecipeSubstitutions from "./RecipeSubstitutions";
import RecipeSteps from "./RecipeSteps";
import RecipeSurprise from "./RecipeSurprise";
import RecipeHistory from "./RecipeHistory";
import RecipeFacts from "./RecipeFacts";
import { useAppSelector } from "../../../services/hooks";
import { selectModal } from "../../../services/slicers/modalSlice";
import LoginModal from "../../../components/modals/LoginModal";
import { useGetPublicLessonPlanQuery } from "../../../services/endpoints/content/lessonPlan";
import LessonPlanShoppingList from "./LessonPlanShoppingList";
import LessonPlanChecklist from "./LessonPlanChecklist";
import { getCurrentUser } from "../../../services/helper";
import LessonPlanTimeline from "./LessonPlanTimeline";
import LessonPlanNotes from "./LessonPlanNotes";
import LessonPlanChatter from "./LessonPlanChatter";
import { Snippet } from "../../../types/website.types";
import { useGetAllSnippetsQuery } from "../../../services/endpoints/website/snippet";

const currentUser = getCurrentUser();

interface SingleRecipeProps {
  isLessonPlan?: boolean;
}
const SingleRecipe: React.FC<SingleRecipeProps> = ({ isLessonPlan }) => {
  const [recipe, setRecipe] = useState<Recipe | null>(null);
  const [lessonPlan, setLessonPlan] = useState<LessonPlan | null>(null);
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const params = useParams();
  const id = +params.id!;
  const { data: recipeData } = useGetRecipeQuery(id, { skip: isLessonPlan });
  const { data: lessonPlanData } = useGetPublicLessonPlanQuery(id, {
    skip: !isLessonPlan,
  });
  const {  data: snippetsData } = useGetAllSnippetsQuery({ data: {
    filterBy: [
      {
        operator: 'includes',
        field: 'name',
        value: ['no-prep', 'nut-free']
      }
    ]
  }}, { skip: !isLessonPlan && !currentUser?.hasDashAccess });
  const showModal = useAppSelector(selectModal);

  useEffect(() => {
    if (recipeData?.recipe) {
      setRecipe(recipeData.recipe);
    }
    if (lessonPlanData?.lessonPlan) {
      setLessonPlan(lessonPlanData.lessonPlan);
      const findPrimaryRecipe = getPrimaryRecipe(lessonPlanData.lessonPlan);
      if (findPrimaryRecipe) {
        // setPrimaryRecipe(findPrimaryRecipe);
        setRecipe(findPrimaryRecipe);
      }
    }
  }, [recipeData, lessonPlanData]);

  useEffect(() => {
    if (snippetsData?.results) {
      setSnippets(snippetsData.results);
    }
  }, [snippetsData]);

  const scrollToElement = (elementId: string = 'ingredients') => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  
  const handleRedirect = () => {
    window.location.href = "https://www.amazon.com/stores/author/B093YJHWSM/allbooks?ingress=0&visitId=40d233ce-6f77-44b4-88e1-623a39c5edc5&store_ref=ap_rdr&ref_=ap_rdr";
  }

  return (
    <>
      <LoginModal show={showModal.show} />
      {recipe && (
        <div style={{ backgroundColor: "#FFF" }}>
          <Banner
            title={lessonPlan ? lessonPlan.name : recipe.title}
            pageTitle={`${isLessonPlan ? (currentUser?.hasDashAccess ? "Lesson Plan" : "Family Meal Plan") : "Recipe"}: ${
              recipe.title
            }`}
            mainPage={isLessonPlan ? (currentUser?.hasDashAccess ? "Dashboard": "Recipes") : "Recipes"}
            mainPageUrl={isLessonPlan ? (currentUser?.hasDashAccess ? "/admin" : "/recipes") : "/recipes"}
          />
          <div className="section-content">
            <div className="container">
              <Row>
                <Col lg={8}>
                  <div>
                    <h2
                      className="text-info fw-bold"
                      style={{ marginBottom: "20px" }}
                    >
                      {recipe.title}
                    </h2>
                    <div className="subheader">by {recipe.author}</div>
                    <RecipeSocial recipe={recipe} lessonPlan={lessonPlan} />
                  </div>
                  <div>
                    {recipe.recipe_images?.length && (
                      <>
                        <img
                          src={getRecipeImage(recipe, 'original') ?? ""}
                          alt={recipe.title}
                          className="image-recipe"
                        />
                        <div className="subheader tiny align-left">
                          Photo By {recipe.recipe_images[0].source}
                        </div>
                      </>
                    )}
                  </div>
                  <RecipeStats recipe={recipe} lessonPlan={lessonPlan} />
                  {recipe.story && (
                    <RecipeStory
                      recipe={recipe}
                      scrollToRecipe={scrollToElement}
                    />
                  )}
                  {
                    lessonPlan?.shopping_list_items?.length && (
                      <LessonPlanShoppingList lessonPlan={lessonPlan} />
                    )
                  }
                  {
                    (lessonPlan && currentUser?.hasDashAccess) && (
                      <LessonPlanChecklist lessonPlan={lessonPlan} scrollToSteps={scrollToElement} snippets={snippets} />
                    )
                  }
                  {
                    (lessonPlan && currentUser?.hasDashAccess) && (
                      <LessonPlanTimeline lessonPlan={lessonPlan} scrollToSteps={scrollToElement} snippets={snippets} />
                    )
                  }
                  {!lessonPlan && recipe.kitchen_skills.length > 0 && (
                    <RecipeSkills recipe={recipe} />
                  )}
                  {lessonPlan?.recipes?.length && (
                    <RecipeSkills lessonPlan={lessonPlan} />
                  )}
                  {
                    (lessonPlan?.notes && currentUser?.hasDashAccess) && <LessonPlanNotes lessonPlan={lessonPlan} scrollToSteps={scrollToElement} />
                  }
                  <RecipeEquipment recipe={recipe} />
                  {
                    !isLessonPlan && <RecipeIngredients recipes={[recipe]} />
                  }
                  {
                    lessonPlan && <RecipeIngredients recipes={lessonPlan.recipes} />
                  }
                  {(!lessonPlan && recipe.substitutions) && (
                    <RecipeSubstitutions recipes={[recipe]} />
                  )}
                  {(lessonPlan) && (
                    <RecipeSubstitutions recipes={lessonPlan.recipes} />
                  )}
                  { !lessonPlan && <RecipeSteps recipes={[recipe]} /> }
                  { lessonPlan && <RecipeSteps recipes={lessonPlan.recipes} /> }
                  { lessonPlan && <LessonPlanChatter scrollToSteps={scrollToElement} /> }
                  {recipe.featured_ingredient && (
                    <RecipeSurprise
                      recipe={recipe}
                      scrollToRecipe={scrollToElement}
                    />
                  )}
                  {recipe.food_history && <RecipeHistory recipe={recipe} />}
                  {recipe.country_fact && <RecipeFacts recipe={recipe} />}
                  <RecipeSocial recipe={recipe} lessonPlan={lessonPlan} />
                </Col>
                <Col lg={4} className="recipe-child-sidebar">
                  <div className="recipe-child-sidebar">
                    <RecipeJokes lessonPlan={lessonPlan} recipe={recipe} />
                    <div className="sidebar-cta">
                      <div className="break"></div>
                      <div>
                        <h4 className="text-info fw-bolder mt-4 mb-2">Shop Our Cookbooks</h4>
                        <div>Now available on Amazon! Our cookbooks feature kid-tested recipes that build confidence in the kitchen. Expand your child&#x27;s palate and spark a love of healthy foods with a Sticky Fingers Cooking cookbook.</div>
                        <Button variant="primary" className="mt-3 shop-button" onClick={handleRedirect}>SHOP NOW</Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRecipe;
