import { Modal, Row, Col, Button } from "react-bootstrap";
import Login from "../../features/user/Login";
import { Image } from "react-bootstrap";
import SFCLogoMD from "../../assets/images/sticky-fingers-logo-stacked-2.png";
import "./LoginModal.css";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../services/hooks";
import { hideModal } from "../../services/slicers/modalSlice";
interface LoginModalProps {
  show: boolean;
}

const LoginModal: React.FC<LoginModalProps> = ({ show }) => {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const handleClose = () => {
    dispatch(hideModal());
  };
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ paddingTop: "25px", paddingBottom: "40px" }}>
        <Row>
          <Col lg={12} className="logo-modal">
            <Image
              src={SFCLogoMD}
              style={{ width: 140 }}
              alt="Sticky Fingers Logo"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="login-modal">
            <div className="login-modal-form">
              <h2 className="text-info fw-bolder">Log In</h2>
              <p>
                We're a great <em>pear</em>!
              </p>
              <Login isModal={true} />
            </div>
          </Col>
          <Col lg={6} className="sign-up-modal">
            <div className="sign-up-content">
              <h2 className="text-info fw-bolder">Create a New Account</h2>
              <p>
                <em>Lettuce</em> be friends! Create your account here.
              </p>
              <Button
                className="text-uppercase sign-up-btn"
                variant="outline-primary"
                onClick={() => navigate("/users/register")}
              >
                Sign Up
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
