import { ButtonData } from '../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../components/tables/SFCTable';
import { ColumnDef, Getter, Row } from '@tanstack/react-table';
import { Scopes, SFCLocation, useGetAdminsQuery, useMimicMutation, User } from '../../../../services/endpoints/people/user';
import { userTypeNames, userTypes, usTimezones } from '../../../../utils/consts';
import { ExpanderCell } from '../../../../components/tables/cellComponents/ExpanderCell';
import { getErrorMessage, getKeyByValue } from '../../../../utils/utils';
import { BadgeCell } from '../../../../components/tables/cellComponents/BadgeCell';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { Button } from 'react-bootstrap';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useToast } from '../../../../context/ToastContext';
import { getCurrentUser } from '../../../../services/helper';
import { GetQueryParams } from '../../../../types/api.type';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import { useDataContext } from '../../../../context';

interface DataRow extends User {
  log_in_as: (id: number) => void;
}

const defaultScope = 'admins';

export const AdminsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading, isFetching } = useGetAdminsQuery(filters);
  const [scopes, setScopes] = useState<Scopes>({});
  const [users, setUsers] = useState<User[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [mimic] = useMimicMutation();
  const currentUser = getCurrentUser();
  const canMimic = currentUser?.isSuper;

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'location_id',
      label: 'Location',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'first_name',
      label: 'First Name',
    },
    {
      name: 'last_name',
      label: 'Last Name',
    },
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State',
    },
    {
      name: 'zip',
      label: 'Zip',
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'timezone',
      label: 'Timezone',
      element: (
        <FormSelect
          name="timezone"
          label="Timezone"
          options={usTimezones.map((tz) => ({ value: tz, label: tz }))}
        />
      ),
    },
  ];

  const columns: ColumnDef<DataRow>[] = [
    {
      id: 'expander',
      enableSorting: false,
      cell: ({ row }) => row.original?.children && row.original?.children?.length > 0 ? (
        <ExpanderCell row={row} />
      ) : null,
    },
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'First Name',
      accessorKey: 'first_name',
      cell: ({ getValue, row }) => (
        <LinkCell content={getValue<string>()} url={`/admin/dash-users/admins/${row.original.id}`} />
      ),
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Location',
      accessorKey: 'location',
      enableSorting: false,
      cell: ({ getValue }) => (
        <span className="">
          {getValue<SFCLocation>()?.title}
        </span>
      ),
    },
    {
      header: 'User Type',
      accessorKey: 'user_type_id',
      cell: ({ getValue }) => (
        <span className="">
          {userTypeNames[getKeyByValue(userTypes, getValue<number>())!]}
        </span>
      ),
    },
    {
      header: 'Active',
      accessorKey: 'active',
      cell: ({ getValue }) => (
        <BadgeCell active={getValue<boolean>()} />
      ),
    },
    ...(canMimic ? [{
      header: 'Impersonate',
      accessorKey: 'log_in_as',
      enableSorting: false,
      cell: ({ getValue, row }: { getValue: Getter<unknown>, row: Row<DataRow> }) => (
        <Button
          size="sm"
          variant="sfc-orange"
          className="text-truncate"
          onClick={() => getValue<(id: number) => void>()(row.original.id)}
        >
          Log in as...
        </Button>
      ),
    }] : []),
  ];

  const loginAs = (userId: number) => {
    mimic(userId).unwrap().then((r) => {
      addToast('Logged in successfully', 'success');
      navigate("/");
    }, 
    (error: any) => {
      addToast(getErrorMessage(error), 'error');
    })
  };

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => ({
          title: title,
          key: scope,
        }))));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
        const results = data.results.map((user) => ({
          ...user,
          log_in_as: loginAs,
        }))
        setUsers(results);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };
  
  return (
    <div className="py-2">
      <SFCTable
        name={scopes[filters?.scope!]}
        columns={columns}
        data={users}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='users/admins'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={210}
            onClick={() => navigate('/admin/dash-users/admins/new')}
          >
            New admin
          </SimpleButton>
        )}
      />
    </div>
  )
}
