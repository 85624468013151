import React from 'react';
import { UTCTimeToLocal, formatDate } from '../../../utils/dateUtils';
import { BsClock } from 'react-icons/bs';
import { Course } from '../../../types/course.type';
import { ReactComponent as ChefHat } from '../../../assets/icons/chef-hat.svg';

export const StylePink: React.CSSProperties = {
  fill: `#BA1F69`,
}

export const StyleOrange: React.CSSProperties = {
  fill: `#d36a48`,
}

export const StyleGreen: React.CSSProperties = {
  fill: `#B4C634`,
}

export const course_type_style_lookup = (key: string) => {
  switch (key) {
    case 'after_school': return StylePink;
    case 'camp': return StyleOrange;
    case 'event': return StyleGreen;
    default: return {};
  }
}

interface CourseDatesProps {
  startDate: Date;
  endDate?: Date;
}

export const CourseDates: React.FC<CourseDatesProps> = ({ startDate, endDate }) => {
  return (
    <span className="text-truncate">{formatDate(startDate)} {endDate ? `- ${formatDate(endDate)}` : ''}</span>
    )
  }
  
interface CourseTimesProps {
  course: Course;
  dayOfWeek?: string;
}

export const CourseTimes: React.FC<CourseTimesProps> = ({course, dayOfWeek}) => {
  const times = course.formatted_time ? course.formatted_time.split(' - ') : [course.starts_at, course.ends_at];
  let start = UTCTimeToLocal(times[0]);
  let end = UTCTimeToLocal(times[1]);
  if ((start?.includes('AM') && end?.includes('AM')) 
    || (start?.includes('PM') && end?.includes('PM'))) {
      start = start.replace(/\sAM|\sPM/, '');
  }

  const style = {
    transform: 'translateY(-2px)',
    ...course_type_style_lookup(course.course_type),
  }
  return (
    <div style={{alignItems: 'center', display: 'flex'}} className='flex-wrap-mobile'>
      <BsClock />&nbsp;{dayOfWeek && (<span className="fw-light ff-inter">{dayOfWeek}</span>)}&nbsp;
      <span>{start} - {end}</span>
      <div>&nbsp;|&nbsp;{<ChefHat style={style} />}&nbsp;{course.lessons.length}&nbsp;Classes</div>
    </div>
  )
}

interface CourseDateTimesProps {
  course: Course;
  format?: string;
}

export const CourseDateTimes: React.FC<CourseDateTimesProps> = ({course, format}) => {
  return (
    <div>
      <CourseDates startDate={new Date(course.starts_on)} endDate={new Date(course.ends_on)} />
      <CourseTimes course={course} dayOfWeek={course.day_of_week} />
    </div>
  )
}