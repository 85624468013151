import { Button } from "react-bootstrap";
import "./RecipeCardFavorite.css";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useToggleRecipeFavoriteMutation } from "../../../services/endpoints/content/recipe";
import { LessonPlan, Recipe } from "../../../types/lessonPlan.type";
import { useEffect, useState } from "react";
import { useToast } from "../../../context";
import { getErrorMessage } from "../../../utils/utils";
import { useAppDispatch } from "../../../services/hooks";
import { showModal } from "../../../services/slicers/modalSlice";
import { getCurrentUser } from "../../../services/helper";
import { useToggleLessonPlanFavoriteMutation } from "../../../services/endpoints/content/lessonPlan";

interface RecipeCardFavoriteProps {
  recipe?: Recipe;
  lessonPlan?: LessonPlan | null;
};

const RecipeCardFavorite: React.FC<RecipeCardFavoriteProps> = ({ recipe, lessonPlan }) => {
  const [favorited, setFavorited] = useState<boolean>(false);
  const { addToast } = useToast();
  const currentUser = getCurrentUser();
  const dispatch = useAppDispatch();

  const [toggleRecipeFavorite] = useToggleRecipeFavoriteMutation();
  const [toggleLessonPlanFavorite] = useToggleLessonPlanFavoriteMutation();

  const handleToggleFavorite = async() => {
    if (!currentUser) {
      dispatch(showModal());
      return;
    }
    if (recipe) {
      try {
        const res = await toggleRecipeFavorite(recipe.id).unwrap();
        const val = res.recipeFavorited;
        setFavorited(val);
      } catch (e) {
        addToast(getErrorMessage(e), "error");
      }
    }
    if (lessonPlan) {
      try {
        const res = await toggleLessonPlanFavorite(lessonPlan.id).unwrap();
        const val = res.lessonPlanFavorited;
        setFavorited(val);
      } catch (e) {
        addToast(getErrorMessage(e), "error");
      }
    }
  };

  useEffect(() => {
    if (recipe) {
      setFavorited(recipe.favorited === 0 ? false : true);
    }
    if (lessonPlan) {
      setFavorited(lessonPlan.favorited === 0 ? false : true);
    }
  }, [recipe, lessonPlan])

  return (
    <>
      <Button
        className="recipe-favorite"
        variant="outline-primary"
        onClick={handleToggleFavorite}
      >
        {!favorited && (
          <>
            <FaRegHeart className="favorite-icon" />
            SAVE
          </>
        )}
        {favorited && (
          <>
            <FaHeart className="favorite-icon" />
            UNSAVE
          </>
        )}
      </Button>
    </>
  );
};

export default RecipeCardFavorite;  