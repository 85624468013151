import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ReactComponent as SFCLogoLarge } from "../../assets/icons/sfc-logo-large.svg";
import { NavItem, SFCNavItems } from "./NavBarItems";
import { BsPersonCircle } from "react-icons/bs";
import { MdLogin, MdLogout } from "react-icons/md";
import CartIcon from '../../components/cartIcon/CartIcon';
import { getCurrentUser, isAuthenticated, logoutUser } from '../../services/helper';
import { hasDashAccess } from '../../utils/consts';
import { LocationSetter } from '../maps/LocationSetter';
import "./SFCNavbar.css";
import { useCartContext } from "../../context/CartContext";
import { Link } from "react-router-dom";

const calcBorderRadius = (idx: number, length: number) => {
  if (idx === 0 && length === 1) return "8px 0 8px 8px";
  if (idx === length - 1) return "0 0 8px 8px";
  if (idx === 0) return "8px 0 0 0";
  return "0";
};

// TODO: missing mobile design and styling
export const SFCNavbar = () => {
  const authenticated = isAuthenticated();
  const currentUser = getCurrentUser();
  const cartContext = useCartContext();

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      className="py-0"
      style={{
        position: "sticky",
        top: 0,
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, .25)",
        zIndex: 100,
        backgroundColor: "white",
      }}
    >
      <Container className="flex-xl-column">
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="header-container"
        >
          <div className="navbar-emptyspace"></div>
          <div className="logo-container">
            <Navbar.Brand href="/">
              <SFCLogoLarge className="w-100" />
            </Navbar.Brand>
          </div>
          <div className="navbar-container">
            <div className="navbar-buttons">
              <LocationSetter />

              <Link
                to="/cart"
                className="fw-bold text-uppercase text-truncate ms-3 me-3 text-dark"
              >
                <CartIcon itemCount={cartContext.courseIds.length} />
              </Link>
              {authenticated ? (
                <NavDropdown title={<BsPersonCircle size={24} />} align="end">
                  {hasDashAccess(currentUser?.user_type_id!) && (
                    <NavDropdown.Item
                      href="/admin"
                      className="fw-semibold"
                      style={{ borderRadius: calcBorderRadius(0, 3) }}
                    >
                      The Dash
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    href="/user/profile"
                    className="fw-semibold"
                  >
                    My Profile
                  </NavDropdown.Item>
                  <hr style={{ width: "85%", margin: "10px auto" }} />
                  <NavDropdown.Item
                    href="#profile"
                    className="d-flex justify-content-between fw-semibold"
                    style={{ borderRadius: calcBorderRadius(2, 3) }}
                    onClick={() => logoutUser()}
                  >
                    Logout
                    <MdLogout size={20} />
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link
                  href="/users/login"
                  className="fw-bold text-uppercase text-truncate ms-2"
                >
                  <MdLogin size={24} />
                </Nav.Link>
              )}
            </div>
          </div>
        </Navbar.Collapse>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav variant="pills">
            {SFCNavItems.map((navItem: NavItem, index) =>
              navItem.subItems?.length ? (
                <NavDropdown
                  key={index}
                  title={navItem.title}
                  id="collapsible-nav-dropdown"
                  className="fw-bold text-uppercase"
                  align="end"
                >
                  {navItem.subItems
                    .filter((f) => !f.hidden)
                    .map((subItem: NavItem, idx: number, array: NavItem[]) => (
                      <NavDropdown.Item
                        key={idx}
                        href={subItem.url}
                        className="text-capitalize fw-semibold"
                        style={{
                          borderRadius: calcBorderRadius(idx, array.length),
                        }}
                      >
                        {subItem.title}
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
              ) : (
                <Nav.Link
                  key={index}
                  href={navItem.url}
                  className="fw-bold text-uppercase text-truncate"
                >
                  {navItem.title}
                </Nav.Link>
              )
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
