import {
  Col,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { StepProps } from "./AddEditTrainingVideo";

export const TrainingVideosForm = ({
  touched,
  errors,
  values,
  setFieldValue,
}: StepProps) => {
  
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Name
      </h6>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Field
            name="name"
            type="text"
            as={Form.Control}
            isInvalid={touched.name && !!errors.name}
          />
          <ErrorMessage name="name">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="is_public">
          <Form.Label>Category</Form.Label>
          <Form.Control
            as="select"
            name="category"
            value={values?.category}
            onChange={(e) => setFieldValue!("category", e.target.value)}
            isInvalid={touched.category && !!errors.category}
          >
            <option>Select</option>
            <option value="training_day_1">Training day 1</option>
            <option value="training_day_2">Training day 2</option>
            <option value="training_day_3">Training day 3</option>
            <option value="classroom_management">Classroom management</option>
            <option value="cooking_skills">Cooking skills</option>
            <option value="private_parties">Private parties</option>
            <option value="summer_camp">Summer camp</option>
            <option value="food_safety">Food safety</option>
          </Form.Control>
          <ErrorMessage name="category">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      <Col xs={12} lg={6} className="pe-1">
        <Form.Group className="mb-1 mb-lg-3" controlId="name">
          <Form.Label>Code</Form.Label>
          <Field
            name="code"
            type="text"
            as={Form.Control}
            isInvalid={touched.code && !!errors.code}
          />
          <ErrorMessage name="code">
            {(msg) => (
              <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
            )}
          </ErrorMessage>
        </Form.Group>
      </Col>
      
    </Row>
  );
};
