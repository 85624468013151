import { Button } from "react-bootstrap";
import { Recipe } from "../../../types/lessonPlan.type";
import { FaBlender } from "react-icons/fa";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";

interface RecipeStoryProps {
    recipe: Recipe;
    scrollToRecipe: () => void;
};

const RecipeStory: React.FC<RecipeStoryProps> = ({
  recipe,
  scrollToRecipe,
}) => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="text-info fw-bolder">Fun Food Story</h3>
          <Button
            className="recipe-print-button"
            variant="outline-primary"
            onClick={scrollToRecipe}
          >
            <FaBlender className="print-icon" />
            Skip To Recipe
          </Button>
        </div>
        <div className="fun-food-story">
          <h4 className="text-info fw-bold" style={{ marginTop: "20px" }}>
            {recipe.title}
          </h4>
          <div>
            <SafeHTMLDisplay htmlContent={recipe.story} />
          </div>
          <p>Happy & Healthy Cooking,</p>
          <div className="text-block-signature">
            Chef Erin, Food-Geek-in-Chief
          </div>
        </div>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeStory;